<h3 class="title">KALP</h3>

<div class="group-holder">
  <div class="subgroup-holder">

    @if (!loanPromiseData.kalpIncludingOtherProperties && !loanPromiseData.kalpExcludingOtherProperties) {
      <spb-kalp-item
        format="1.0-0"
        text="KALP"
        [value]="loanPromiseData.kalp"></spb-kalp-item>
    }

    @if (loanPromiseData.kalpIncludingOtherProperties) {
      <spb-kalp-item
        [textValue]="false"
        format="1.0-0"
        text="KALP inklusive existerande bostad"
        [value]="loanPromiseData.kalpIncludingOtherProperties"></spb-kalp-item>
    }

    @if (loanPromiseData.kalpExcludingOtherProperties) {
      <spb-kalp-item
        [textValue]="false"
        format="1.0-0"
        text="KALP exklusive existerande bostad"
        [value]="loanPromiseData.kalpExcludingOtherProperties"></spb-kalp-item>
    }

    <spb-kalp-item
      format="1.0-0"
      text="KALP endast existerande bostad"
      [value]="loanPromiseData.rawKalp"></spb-kalp-item>

    <!--    Investigate and put back if we want this-->
    <!--    <spb-kalp-item-->
    <!--      [format]="'1.0-0'"-->
    <!--      [text]="'Högsta möjliga lån (beräknat)'"-->
    <!--      [value]="loanPromiseData.maxPropertyLoan"></spb-kalp-item>-->
  </div>
</div>