@if (loanPromiseData.applicants.length > 0) {
  <h3 class="title">Grunduppgifter</h3>

  <!-- Applicants information -->
  <div class="multi-group-holder">
    @for (applicant of loanPromiseData.applicants; track $index) {
      <div class="group-holder">
        <h3
          class="subtitle initial">{{ $first ? 'Sökande' : 'Medsökande' }}</h3>
        <div class="subgroup-holder">
          <spb-kalp-item
            [textValue]="true"
            text="Namn"
            [value]="applicant.displayName || applicant.contact.name"></spb-kalp-item>
          <spb-kalp-item
            [textValue]="true"
            text="Telefon"
            [value]="applicant.contact.phone"></spb-kalp-item>
          <spb-kalp-item
            [textValue]="true"
            text="Epost"
            [value]="applicant.contact.email"></spb-kalp-item>
          <spb-kalp-item
            [textValue]="true"
            text="Personnummer"
            [value]="applicant.personNummer"></spb-kalp-item>
          @if (applicant.employer) {
            <spb-kalp-item
              [textValue]="true"
              text="Arbetsgivare"
              [value]="applicant.employer"></spb-kalp-item>
          }
          <spb-kalp-item
            [textValue]="true"
            format="boolean"
            text="A-kassa"
            [value]="applicant.akassa ? 'Ja' : 'Nej'"></spb-kalp-item>
          @if (applicant.spouse) {
            <spb-kalp-item
              [textValue]="true"
              text="Partner/sambo/make/maka"
              [value]="applicant.spouse"></spb-kalp-item>
          }
        </div>

        @if ($first) {
          <div class="subgroup-holder">
            <spb-kalp-item
              [textValue]="false"
              format="1.0-0"
              text="Sökt lånebelopp"
              [value]="loanPromiseData.property.loanAmount"></spb-kalp-item>
            <spb-kalp-item
              [textValue]="false"
              format="1.0-0"
              text="KALP"
              [value]="loanPromiseData.kalp"></spb-kalp-item>
          </div>
        }
      </div>
    }
  </div>

  <div [style.max-width.px]="500" [style.word-break]="'break-word'">
    <h4>Övriga upplysningar</h4>
    <p>{{ loanPromiseData.otherInfo ?? '----' }}</p>
  </div>

  <!-- Applicants income information -->
  <div class="multi-group-holder">
    @for (applicant of loanPromiseData.applicants; track $index) {
      <div class="group-holder">
        <h3 class="subtitle">{{ $first ? 'Sökande' : 'Medsökande' }}</h3>
        <div class="error">
          @if (status.needsBlancoLoansThreshold || status.needsBlancoLoansMatchingUC) {
            <p>
              Blancolån enligt
              UC: {{ (applicant.uc!.blancoLoans) |number }} kr
            </p>
          }
          @if (status.needsUCAboveThreshold) {
            <p>
              Månadsinkomst enligt
              UC: {{ (+applicant.uc!.income[0] / 12) | number: '1.0-0' }} kr
            </p>
          }

          <!--Housing loans compared to UC, needs to be divided by 2 if applicants have same amount-->
          @if (status.needsPropertyLoansMatchingUC) {
            @if ((loanPromiseData.applicants[0] && loanPromiseData.applicants[0].uc!.propertyLoans) === (loanPromiseData.applicants[1] && loanPromiseData.applicants[1].uc!.propertyLoans)) {
              @if (applicant && applicant.uc && applicant.uc.propertyLoans) {
                <p>Bostadslån enligt
                  UC: {{ (applicant.uc.propertyLoans / 2)| number }}
                  kr (delas av 2 låntagare)</p>
              }
            } @else {
              <p>Bostadslån enligt
                UC: {{ applicant.uc!.propertyLoans | number }}
                kr</p>
            }
          }
        </div>
        <div class="subgroup-holder">
          <spb-kalp-item
            format="1.0-0"
            text="Inkomst"
            [value]="applicant.income"
            description=""></spb-kalp-item>
          <div class="error">{{ status.incomeDiffs[$index] }}
            @if (applicant.monthlyReducedIncome && applicant.monthlyReducedIncome !== applicant.income) {
              <p>
                Reducerad inkomst  {{ applicant.monthlyReducedIncome }}
              </p>
            }
          </div>

          @if (applicant.tax) {
            <spb-kalp-item
              format="1.0-0"
              text="Skatt"
              [value]="applicant.tax.tax"
              description=""></spb-kalp-item>
          }
          <spb-kalp-item
            [textValue]="true"
            text="Sysselsättning"
            [value]="applicant.occupation | occupationType"></spb-kalp-item>
          <spb-kalp-item
            format="1.0-0"
            text="Levnadsomkostnad"
            [value]="applicant.livingExpense"
            description=""></spb-kalp-item>
          @if (applicant.spouseExtraLivingExpense) {
            <spb-kalp-item
              format="1.0-0"
              text="Extra levnadsomkostnad (försörjningsplikt)"
              [value]="applicant.spouseExtraLivingExpense"
              description=""></spb-kalp-item>
          }
          @if (applicant.tax) {
            <spb-kalp-item
              format="1.0-0"
              text="Skattekolumn"
              [value]="applicant.tax.taxColumn"></spb-kalp-item>
            <spb-kalp-item
              format="2.2-3"
              suffix="%"
              text="Skatt i kommun"
              [value]="applicant.tax.taxValue"
              description=""></spb-kalp-item>

            @if (applicant.tax.taxPercent) {
              <spb-kalp-item
                format="2.2-3"
                suffix="%"
                text="Skatt i procent"
                [value]="applicant.tax.taxPercent"></spb-kalp-item>
            } @else {
              <spb-kalp-item
                [textValue]="true"
                text="Skatt i procent"
                value="-"></spb-kalp-item>
            }
          }

          <h4>Räntor</h4>
          @if (applicant.taxReduction) {
            <spb-kalp-item
              format="1.0-0"
              text="Ränta"
              [value]="applicant.taxReduction.interest"></spb-kalp-item>
            <spb-kalp-item
              format="1.0-0"
              text="Återbäring totalt (kr/år)"
              [value]="applicant.taxReduction.interestTaxReturn"></spb-kalp-item>
            <spb-kalp-item
              format="1.0-0"
              text="Återbäring 21% (kr/år)"
              [value]="applicant.taxReduction.lowReturn"></spb-kalp-item>
          }
        </div>
      </div>
    }
  </div>

  <!-- Children information -->
  <div class="group-holder">
    <h4>Barn</h4>
    <div class="subgroup-holder">
      <spb-kalp-item
        format="1.0-0"
        text="Antal barn"
        [value]="loanPromiseData.childrenCount"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Månadskostnad för barnomsorg"
        [value]="loanPromiseData.monthlyChildrenCareCost"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Total kostnad barn"
        [value]="loanPromiseData.childrenCost"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Barnbidrag"
        [value]="loanPromiseData.childrenBenefit"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Flerbarnstillägg"
        [value]="loanPromiseData.childrenBenefitExtra"></spb-kalp-item>
    </div>

    <div class="subgroup-holder">
      <div class="multi-group-holder">
        @for (child of loanPromiseData.children; track $index) {
          <div class="group-holder fitted">
            <div class="subgroup-holder">
              <spb-kalp-item
                format="1.0-0"
                [text]="$first ? 'Barn' : ''"
                [value]="$index + 1"></spb-kalp-item>
              <spb-kalp-item
                format="1.0-0"
                [text]="$first ? 'Ålder' : ''"
                [value]="child.age"></spb-kalp-item>
              <spb-kalp-item
                format="1.0-0"
                [text]="$first ? 'Kostnad' : ''"
                [value]="child.monthlyCost"></spb-kalp-item>
            </div>
          </div>
        }
      </div>
    </div>
  </div>

  <!-- Cars information -->
  <div class="group-holder">
    @if (loanPromiseData.cars) {
      <h4>Bil</h4>

      <div class="subgroup-holder">
        <spb-kalp-item
          format="1.0-0"
          text="Antal bilar"
          [value]="loanPromiseData.cars.numOfCars"></spb-kalp-item>

        @for (car of loanPromiseData.cars.individualCars; track $index) {
          <spb-kalp-item
            format="1.0-0"
            [text]="'Bil ' + ($index + 1)  + (car.carType === 'OWN' ? ' (ägd)' : ' (leasing)')"
            [value]="car.monthlyCost"></spb-kalp-item>
        }
      </div>
    }
  </div>
}