<!-- Icon column -->
<div class="column icon">
  <spb-kalp-type-indicator [isOverride]="logItem.override"
                           [type]="logItem.type"></spb-kalp-type-indicator>
</div>

<!-- Date & User column -->
<div class="column">
  <div class="date">{{ logItem.date | date : 'yyyy-MM-dd HH:mm' }}</div>
  <div
    class="user">{{ loanService.allUsersMap.get(logItem.user)?.name ?? logItem.user }}
  </div>
</div>

<!-- Notes column -->
<div class="column">
  <div>{{ logItem.notes }}</div>

  @if (logItem.changes && logItem.changes.length > 0) {
    <ul class="changes">
      @for (change of logItem.changes; track $index) {
        <li class="change">{{ change }}</li>
      }
    </ul>
  }
</div>

<!--&lt;!&ndash; Check column &ndash;&gt;-->
<!--<div class="column icon">-->
<!--  @if (logItem.override) {-->
<!--    <mat-icon color="accent">check</mat-icon>-->
<!--  }-->
<!--</div>-->