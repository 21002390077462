import {HttpClient} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {APPLICATION_ID, IRegistration} from '@sparbanken-syd/loan-backend'
import {BehaviorSubject, Observable, throwError} from 'rxjs'
import {catchError, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {ServiceBase, Wait} from './service-base.class'

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends ServiceBase {
  private readonly http = inject(HttpClient)
  public registeredPromises$ = new BehaviorSubject<IRegistration[]>([])

  // Utility function to handle error logging or management
  private handleError(error: any): Observable<never> {
    console.error('Error occurred:', error)
    return throwError(() => new Error('Something went wrong with the API request.'))
  }

  @Wait('Sparar')
  public saveRegistered(registeredLoanPromise: IRegistration): Observable<IRegistration> {
    const url = `${environment.loanServiceUrl}/register`
    return this.http.put<IRegistration>(url, registeredLoanPromise).pipe(
      tap((newRegistration) => {
        this.updateRegisteredList([...this.registeredPromises$.value, newRegistration])
      }),
      catchError(this.handleError)
    )
  }

  public getRegistrations(): Observable<IRegistration[]> {
    const url = `${environment.loanServiceUrl}/register`
    return this.http.get<IRegistration[]>(url).pipe(
      tap((registrations) => {
        this.registeredPromises$.next(registrations)
      }),
      catchError(this.handleError)
    )
  }

  @Wait()
  public getRegistered(id: string): Observable<IRegistration> {
    const url = `${environment.loanServiceUrl}/register/${id}`
    return this.http.get<IRegistration>(url).pipe(
      catchError(this.handleError)
    )
  }

  public delete(id: string): Observable<void> {
    const url = `${environment.loanServiceUrl}/register/${id}`
    return this.http.delete<void>(url).pipe(
      tap(() => {
        this.updateRegisteredList(
          this.registeredPromises$.value.filter(lp => lp[APPLICATION_ID] !== id)
        )
      }),
      catchError(this.handleError)
    )
  }

  @Wait('Uppdaterar')
  public updateRegistered(registeredLoanPromise: IRegistration): Observable<IRegistration> {
    const url = `${environment.loanServiceUrl}/register/${registeredLoanPromise[APPLICATION_ID]}`

    return this.http.put<IRegistration>(url, registeredLoanPromise).pipe(
      tap((updatedRegistration) => {
        this.updateRegisteredList(
          this.registeredPromises$.value.map(r =>
            r[APPLICATION_ID] === updatedRegistration[APPLICATION_ID] ? updatedRegistration : r
          )
        )
      }),
      catchError(this.handleError)
    )
  }

  public getLoanDocument(id: string): Observable<{ url: string }> {
    const url = `${environment.loanServiceUrl}/register/print/${id}`
    return this.http.get<{ url: string }>(url).pipe(
      catchError(this.handleError)
    )
  }

  // Utility function to update the registeredPromises$ with sorted registrations
  private updateRegisteredList(updatedList: IRegistration[]): void {
    this.registeredPromises$.next(updatedList
      .sort((a, b) => b.dateOfIssue - a.dateOfIssue))
  }
}
