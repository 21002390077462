<mat-icon [class]="isOverride ? 'override' : ''">
  @switch (type) {
    @case ('change') {
      radio_button_checked
    }
    @case ('issue') {
      request_quote
    }
    @case ('income') {
      paid
    }
    @case ('ucThreshold') {
      credit_card
    }
    @case ('blancoLoansThreshold') {
      credit_card
    }
    @case ('blancoLoansMatchingUC') {
      credit_card
    }
    @case ('propertyLoansMatchingUC') {
      home
    }
    @case ('occupation') {
      work
    }
    @case ('oldProperty') {
      home
    }
    @case ('note') {
      note_alt
    }
  }
</mat-icon>
