import {DatePipe} from '@angular/common'
import {Component, inject, Input} from '@angular/core'
import {IApplicationLog} from '@sparbanken-syd/loan-backend'
import {LoanService} from '../../../../services/loan.service'
import {
  KalpTypeIndicatorComponent
} from '../kalp-type-indicator/kalp-type-indicator.component'

@Component({
  selector: 'spb-note-item',
  templateUrl: './note-item.component.html',
  styleUrl: './note-item.component.scss',
  imports: [KalpTypeIndicatorComponent, DatePipe]
})
export class NoteItemComponent {
  protected loanService: LoanService = inject(LoanService)

  @Input({required: true}) logItem!: IApplicationLog
}
