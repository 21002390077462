import {Component, inject, OnInit} from '@angular/core'
import {
  MatCard,
  MatCardAvatar,
  MatCardContent,
  MatCardHeader,
  MatCardTitle
} from '@angular/material/card'
import {MatIcon} from '@angular/material/icon'
import {Router, RouterLink} from '@angular/router'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {filter, first} from 'rxjs'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [BankIdComponent, MatCard, MatCardHeader, MatCardAvatar, MatIcon, MatCardTitle, RouterLink, MatCardContent]
})
export class HomeComponent implements OnInit {
  protected readonly environment = environment

  /**
   * The URL BankID shall use.
   */
  protected bankIdUrl = environment.commonServiceUrl

  /**
   * The domain we use for access
   */
  protected domain = environment.domain

  protected configService = inject(ConfigService)

  private router = inject(Router)

  public ngOnInit(): void {
    this.configService.logInState$
      .pipe(filter(Boolean), first())
      .subscribe({
        next: (v): any => {

          if (v.admin && v.registrar) {
            return this.router.navigate(['admin'])
          }
          if (v.admin) {
            return this.router.navigate(['admin', 'classic'])
          }
          if (v.readOnly) {
            return this.router.navigate(['admin', 'classic'])
          }
          if (v.registrar) {
            return this.router.navigate(['admin', 'register'])
          }
          // TODO: Remove if (v.registrar?)
        }
      })
  }
}
