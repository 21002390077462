@if (loanPromiseData.taxReduction) {
  <h3 class="title">Ränteavdrag</h3>
  <p>Ränteavdrag beräknas på samtliga bostadslån och dras av i kalpen som en post för
    alla lån</p>

  <div class="group-holder">
    <div class="subgroup-holder">
      <spb-kalp-item
        format="1.0-0"
        text="Total ränta (kr/mån)"
        [value]="loanPromiseData.taxReduction.totalInterestMonth"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Total ränta (kr/år)"
        [value]="loanPromiseData.taxReduction.totalInterestYear"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Summa ränteavdrag (kr/mån)"
        [value]="loanPromiseData.taxReduction.taxDeductionMonth"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Summa ränteavdrag (kr/år)"
        [value]="loanPromiseData.taxReduction.taxDeductionYear"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Varav över 100 tkr (21%) (kr/mån)"
        [value]="loanPromiseData.taxReduction.taxDeduction21Month"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Varav över 100 tkr (21%) (kr/år)"
        [value]="loanPromiseData.taxReduction.taxDeduction21Year"></spb-kalp-item>
    </div>
  </div>
}