import {Component, OnDestroy, OnInit} from '@angular/core'
import {IApplication} from '@sparbanken-syd/loan-backend'
import {filter, Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {KalpItemComponent} from '../kalp-item/kalp-item.component'

@Component({
  selector: 'spb-kalp-tax-reduction',
  templateUrl: './tax-reduction.component.html',
  styleUrls: ['../kalp.component.scss'],
  imports: [KalpItemComponent]
})
export class TaxReductionComponent implements OnInit, OnDestroy {
  public loanPromiseData!: IApplication
  private sub$ = new Subscription()

  constructor(private loanService: LoanService) {
  }

  ngOnInit() {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe((value) => {
        this.loanPromiseData = value
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
