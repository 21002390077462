<div class="holder">
  <div class="text-holder">
    <div>{{ text }}</div>
    <div class="description">{{ description }}</div>
  </div>

  @if (textValue) {
    <div class="text">{{ value }}{{ suffix }}</div>
  } @else {
    <div
      [ngClass]="{ 'sum' : sum }">{{ value | number:format:'fr' }}{{ suffix }}
    </div>
  }
</div>
