import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {
  ApplicationConfig,
  inject,
  LOCALE_ID,
  provideAppInitializer
} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {routes} from './app.routes'
import {LOCAL_STORAGE} from './application/app'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr')
registerLocaleData(localeSv, 'sv-SE')

export const appConfig: ApplicationConfig = {
  providers: [
    // The Config service depends on the SSO Service and the
    // APP_INITIALIZER requires the ConfigService
    {
      provide: LOCAL_STORAGE,
      useFactory: () => localStorage
    },
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({anchorScrolling: 'enabled'})
    ),
    provideAnimationsAsync(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        subscriptSizing: 'dynamic',
        hideRequiredMarker: true, appearance: 'outline'
      }
    },
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv}
  ]
}