<div mat-dialog-title>Uppladdade dokument</div>
<div class="content">
  <p>{{ data.sub }}</p>
  @for (document of data.documents; track $index) {
    <div class="package">
      <div class="name">
        <a [href]="document.viewUrl" target="_blank">{{ document.name }}</a>
      </div>
    </div>
  } @empty {
    <p>Personen har inga dokument</p>
  }
</div>

<mat-dialog-actions>
  <button mat-dialog-close="cancel" mat-raised-button>Stäng</button>
</mat-dialog-actions>