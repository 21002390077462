import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {ConfigService} from '../../services/config.service'
import {PromiseListComponent} from './promise-list/promise-list.component'
import {StatisticsComponent} from './statistics/statistics.component'

export type TViewType = 'list' | 'contact' | 'register'

@Component({
  selector: 'spb-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrl: './admin-content.component.scss',
  imports: [StatisticsComponent, MatButton, PromiseListComponent]
})
export class AdminContentComponent {
  public viewType: TViewType = 'list'

  constructor(protected configService: ConfigService) {

  }
}
