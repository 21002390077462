import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {
  APPLICATION_ID,
  IApplication,
  IApplicationAdminData,
  IApplicationLog,
  IApplicationSummary,
  IApplicationUpdate
} from '@sparbanken-syd/loan-backend'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, filter, Observable, ReplaySubject, tap} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {LoanPromiseStatus} from './loan-promise-status'
import {ServiceBase, Wait} from './service-base.class'

@Injectable({
  providedIn: 'root'
})
export class LoanService extends ServiceBase {

  public users$: BehaviorSubject<SparbankenUser[]> = new BehaviorSubject<SparbankenUser[]>([])

  public readonly allUsersMap = new Map<string, SparbankenUser>()

  /**
   * Interested parties may subscribe to this to have a short list
   * of loan promises to look at.
   */
  public loanPromiseList$: Observable<IApplicationSummary[]>

  private pLoanPromiseList$ = new BehaviorSubject<IApplicationSummary[]>([])
  /**
   * Updated application details (loan promise data)
   */
  public application$ = new BehaviorSubject<IApplication | null>(null)

  public loanPromiseStatus$: Observable<LoanPromiseStatus>
  public pLoanPromiseStatus$ = new ReplaySubject<LoanPromiseStatus>(1)

  constructor(
    private httpClient: HttpClient
  ) {
    super()
    this.loanPromiseList$ = this.pLoanPromiseList$.asObservable()
    this.loanPromiseStatus$ = this.pLoanPromiseStatus$.asObservable()

    this.application$.pipe(
      filter(Boolean)
    ).subscribe({
      next: (application: IApplication) => {
        this.pLoanPromiseStatus$.next(new LoanPromiseStatus(application))
      }
    })
    /**
     * Currently does this on every creation
     */
    this.getUsers()
  }

  /**
   * Fetch existing loan promise
   * @param id - The ID of the promise
   */
  @Wait()
  public fetchComplete(id: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/complete`
    return this.httpClient.get<IApplication>(url).pipe(
      tap(a => this.application$.next(a))
    )
  }

  @Wait()
  public updateApplication(application: IApplication, update: IApplicationUpdate): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${application[APPLICATION_ID]}`
    return this.httpClient.put<IApplication>(url, {application, update})
      .pipe(tap(a => this.application$.next(a)))
  }

  @Wait('Gör UC kontroll', 'Kan ta några ögonblick', 'Felaktigt svar från UC')
  public runUc(id: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/uc`
    return this.httpClient.put<IApplication>(url, {})
      .pipe(
        tap(a => this.application$.next(a)))
  }

  @Wait('Beviljar', 'Beviljar lånelöftet...')
  public issueLoan(id: string, notes: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/issue`
    return this.httpClient.put<IApplication>(url, {notes})
      .pipe(tap(a => this.application$.next(a)))
  }

  @Wait('Avslår', 'Avslår lånelöftet...')
  public declineLoan(id: string, notes: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/decline`
    return this.httpClient.put<IApplication>(url, {notes})
      .pipe(tap(a => this.application$.next(a)))
  }

  public addNotes(id: string, log: IApplicationLog): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/notes`
    return this.httpClient.put<IApplication>(url, log).pipe(
      tap(a => this.application$.next(a))
    )
  }

  public delete(id: string): Observable<void> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.delete<void>(url).pipe(
      map(() => {
        this.pLoanPromiseList$.next(this.pLoanPromiseList$.value.filter(lp => lp['loan-promise-id'] !== id))
        this.application$.next(null)
      })
    )
  }

  @Wait('Uppdaterar', 'Tar en liten stund...')
  public list(): Observable<IApplicationSummary[]> {
    const url = `${environment.loanServiceUrl}/admin/list`
    return this.httpClient.get<IApplicationSummary[]>(url)
      .pipe(
        map(list => {
          this.pLoanPromiseList$.next(list)
          return this.pLoanPromiseList$.value
        })
      )
  }

  @Wait('Laddar', 'Vänligen vänta...')
  public listReadOnly(id: string): Observable<IApplicationSummary[]> {
    const url = `${environment.loanServiceUrl}/admin/list/read-only`
    return this.httpClient.put<IApplicationSummary[]>(url, {id})
      .pipe(
        map(list => {
          this.pLoanPromiseList$.next(list)
          return this.pLoanPromiseList$.value
        })
      )
  }

  public updateAssignee(id: string, data: IApplicationAdminData): Observable<IApplicationSummary> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.put<IApplicationSummary>(url, data)
  }

  public fetchLoanDocument(id: string): Observable<string> {
    const url = `${environment.loanServiceUrl}/documents/${id}`
    return this.httpClient.get<any>(url).pipe(
      map(r => r.url)
    )
  }

  /**
   * Get the UC as HTML
   */
  @Wait()
  public getHtml(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/reports/${personNummer}`
    return this.httpClient.get<any>(url)
  }

  @Wait()
  public getOffices() {
    const url = `${environment.commonServiceUrl}/data/offices`
    return this.httpClient.get<string[]>(url)
  }

  /**
   * Fetch a list of users and save them all in a map with their personnummer and themselves.
   * Also, create a "users$" subject with the filtered users to use in some parts of the app.
   */
  private getUsers(): void {
    const url = `${environment.commonServiceUrl}/users`
    this.httpClient.get<SparbankenUser[]>(url).subscribe(users => {
      users.forEach(user => {
        this.allUsersMap.set(user.sub, user)
      })

      const filtered = users.filter(u => {
        return u.roles.indexOf('admin') !== -1
      })
      this.users$.next(filtered)
    })
  }
}
