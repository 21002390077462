import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {IApplication} from '@sparbanken-syd/loan-backend'
import {filter, Subscription} from 'rxjs'
import {UcDecisionPipe} from '../../../pipes/uc-decision.pipe'
import {LoanService} from '../../../services/loan.service'
import {KalpItemComponent} from '../kalp-item/kalp-item.component'
import {UcReportComponent} from '../uc-report/uc-report.component'

@Component({
  selector: 'spb-kalp-uc-info',
  templateUrl: './uc-info.component.html',
  styleUrls: ['../kalp.component.scss'],
  imports: [MatButton, KalpItemComponent, UcDecisionPipe]
})
export class UcInfoComponent implements OnInit, OnDestroy {
  public application!: IApplication
  public isUcAvailable = true

  private loanService = inject(LoanService)
  private dialog = inject(MatDialog)
  private sub$ = new Subscription()

  public ngOnInit() {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe((application) => {
        this.application = application
        this.isUcAvailable = !this.application.applicants.some(a => !a.uc)
      })
  }

  public getReport(sub: string): void {
    this.loanService.getHtml(sub).subscribe((html) => {
        this.dialog.open(UcReportComponent, {data: html.html})
      }
    )
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
