import {DecimalPipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox'
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatFooterCell, MatFooterCellDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {IStatistics} from '../statistics.component'

@Component({
  selector: 'spb-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatCheckbox, DecimalPipe]
})
export class TableComponent implements OnInit {

  /**
   * Boiler plate table source.
   */
  public tableSource: MatTableDataSource<IStatistics> = new MatTableDataSource<IStatistics>()

  /**
   * Boilerplate what columns to show.
   */
  public columnsToDisplay: string[] = ['total', 'auto_total', 'approved', 'rejected', 'kalp', 'uc', 'pending', 'issuable', 'amount']

  /**
   * A simple checkbox that tells if we should show (or not) percentages in the table.
   */
  public hidePercent = false

  /**
   * The title to show on the tab. Selected based on the range input below.
   */
  public rangeTitle = 'Vecka'

  /**
   * The range to show. A string in (e.g.) 'week', 'day' all etc.
   */
  @Input() range = ''


  /**
   * The data set to display. Made as a selector so that it updates externally.
   * 'year' is special since it cannot be sorted. And when only one year it is really
   * special
   *
   * @param data - The data to display.
   */
  @Input()
  set data(data: IStatistics[]) {
    if (this.range === 'year' && data[1]) {
      data = [data[1], data[0]]
    }

    // This has to be sorted "natural sort", however that is too complicated, so we cheat.
    if (this.range === 'age') {
      data.sort((a, b) => {
        const A = Number.parseInt(a.key, 10)
        const B = Number.parseInt(b.key, 10)
        if (A < B) {
          return -1
        }
        if (A > B) {
          return 1
        }
        return 0
      })
    }
    this.tableSource = new MatTableDataSource<IStatistics>(data)
  }

  public ngOnInit(): void {
    this.hidePercent = false
    this.columnsToDisplay.unshift(this.range)
    switch (this.range) {
      case 'week':
        this.rangeTitle = 'Vecka'
        break
      case 'month':
        this.rangeTitle = 'Månad'
        break
      case 'year':
        this.rangeTitle = 'År'
        break
      case 'weekday':
        this.rangeTitle = 'Veckodag'
        break
      case 'day':
        this.rangeTitle = 'Dag'
        break
      case 'sex':
        this.rangeTitle = 'Kön'
        break
      case 'age':
        this.rangeTitle = 'Ålder'
        break
      case 'all':
        this.rangeTitle = 'Alla'
        this.columnsToDisplay.splice(0, 1)
        break
    }
  }

  /**
   * For the future when we implement a totals row.
   * @param event - currently only checkbox is supported
   * @param key - A selector for selecting what to summarize.
   */
  public getTotal = (event: MatCheckboxChange | undefined, key: string): number => {
    if (key === 'percent') {
      this.hidePercent = event!.checked
    }
    return Number.parseInt(key, 10)
  }
}
