import {Pipe, PipeTransform} from '@angular/core'
import {OccupationType} from '@sparbanken-syd/loan-backend'
import {OccupationTypeMap} from '@sparbanken-syd/loan-backend/dist/shared'

@Pipe({
  name: 'occupationType'
})
export class OccupationTypePipe implements PipeTransform {
  transform(value: OccupationType): string {
    return OccupationTypeMap.get(value) as string
  }
}
