import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'
import {IDocument} from '@sparbanken-syd/user-documents-backend'


@Component({
  selector: 'spb-show-documents',
  templateUrl: './show-documents.component.html',
  styleUrl: './show-documents.component.scss',
  imports: [MatDialogTitle, MatDialogActions, MatButton, MatDialogClose]
})
export class ShowDocumentsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      sub: string;
      documents: IDocument[]
    }) {
  }
}
