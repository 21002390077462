@if (loanPromiseData.loans && loanPromiseData.loans.length > 0 || loanPromiseData.studyLoan) {
  <h3 class="title">Lånekostnader</h3>

  <div class="group-holder">
    @if (loanPromiseData.studyLoan) {
      <h4 class="initial">Studielån</h4>
      <div class="subgroup-holder">
        <spb-kalp-item
          description="Totalt för hushållet (kr/mån)"
          format="1.0-0"
          text="Belopp"
          [value]="loanPromiseData.studyLoan"></spb-kalp-item>
      </div>
    }

    @for (loan of loanPromiseData.loans; track $index) {
      <h4 [class]="$first && !loanPromiseData.studyLoan ? 'initial' : ''">Lån
        nummer {{ $index + 1 }}</h4>
      <div class="subgroup-holder">
        <spb-kalp-item
          format="1.0-0"
          text="Lånebelopp"
          [value]="loan.amount"></spb-kalp-item>
        <spb-kalp-item
          format="1.0-0"
          text="Typ"
          [textValue]="true"
          [value]="loan.type | loanType"></spb-kalp-item>
        <spb-kalp-item
          format="1.0-0"
          suffix="%"
          text="Räntesats"
          [value]="loan.interestPercent! * 100"></spb-kalp-item>
        <spb-kalp-item
          description="exklusive ränteavdrag"
          format="1.0-0"
          [value]="loan.interest"
          text="Ränta (kr/mån)"></spb-kalp-item>
        <spb-kalp-item
          format="1.0-0"
          text="Amortering (%/år)"
          [value]="loan.mortgagePercent! * 100"></spb-kalp-item>
        <spb-kalp-item
          format="1.0-0"
          text="Amortering (kr/mån)"
          [value]="loan.mortgage"></spb-kalp-item>
      </div>
    }
  </div>
}