import {DecimalPipe} from '@angular/common'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {IApplication} from '@sparbanken-syd/loan-backend'
import {combineLatest, filter, Subscription} from 'rxjs'
import {OccupationTypePipe} from '../../../pipes/occupation-type.pipe'
import {LoanPromiseStatus} from '../../../services/loan-promise-status'
import {LoanService} from '../../../services/loan.service'
import {KalpItemComponent} from '../kalp-item/kalp-item.component'

@Component({
  selector: 'spb-kalp-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['../kalp.component.scss'],
  imports: [KalpItemComponent, DecimalPipe, OccupationTypePipe]
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  public status: LoanPromiseStatus = new LoanPromiseStatus({} as any)

  public loanPromiseData!: IApplication

  private loanService = inject(LoanService)

  private sub$ = new Subscription()

  ngOnInit() {
    this.sub$ = combineLatest([
      this.loanService.loanPromiseStatus$.pipe(filter(Boolean)),
      this.loanService.application$.pipe(filter(Boolean))])
      .subscribe((value) => {
        this.status = value[0]
        this.loanPromiseData = value[1]
      })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}
