<div mat-dialog-title>
  @if (data) {
    Redigera
  } @else {
    Registrera
  }
</div>
<div class="holder">

  <form [formGroup]="registerLoanPromiseForm">
    <div>
      <mat-form-field>
        <mat-label>Kundtyp</mat-label>
        <mat-select
          formControlName="type"
          ngDefaultControl
          placeholder="Välj i listan">
          <mat-option [value]="null" disabled>Välj i listan</mat-option>
          <mat-option value="private">Privat</mat-option>
          <mat-option value="company">Företag</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    @if (registerLoanPromiseForm.controls.type.value) {
      @for (applicant of applicants; track $index) {
        <div>
          @if (isPrivate) {
            <mat-form-field>
              <mat-label>
                {{ $first ? 'Personnummer huvudlåntagare' : 'Personnummer medlåntagare' }}
              </mat-label>
              <input
                [formControl]="applicant.controls.sub"
                matInput
                type="tel"
                spbPersonnummerValidator
                [mustBeOver18]="true"
                [otherPersonnummers]="[$first ? applicants[1]?.controls.sub.value : applicants[0]?.controls.sub.value]"
              >
              <mat-error>{{ applicant.controls.sub.errors?.error }}</mat-error>
            </mat-form-field>
          } @else {
            <mat-form-field>
              <mat-label>
                {{ $first ? 'Organisationsnummer huvudlåntagare' : 'Organisationsnummer medlåntagare' }}
              </mat-label>
              <input
                [formControl]="applicant.controls.sub"
                matInput
                type="tel"
                spbPersonnummerValidator
                [canBeOrg]="true"
                [mustBeOver18]="true"
                [otherPersonnummers]="[$first ? applicants[1]?.controls.sub.value : applicants[0]?.controls.sub.value]"
              >
              <mat-error>{{ applicant.controls.sub.errors?.error }}</mat-error>
            </mat-form-field>
          }
          <mat-form-field>
            <mat-label>
              @if (isPrivate) {
                {{ $first ? 'Namn huvudlåntagare' : 'Namn medlåntagare' }}
              } @else {
                {{ $first ? 'Företagsnamn' : 'Företagsnamn medlåntagare' }}
              }

            </mat-label>
            <input
              [formControl]="applicant.controls.name"
              matInput
              (blur)="formatName(applicant.controls.name)"
            >
          </mat-form-field>

          @if ($index === 0) {
            <mat-checkbox
              [formControl]="coApplicantCheckbox"
              color="primary">Medlåntagare
            </mat-checkbox>
          }
        </div>
      }
    }

    <div>
      <mat-form-field>
        <mat-label>Beviljat datum</mat-label>
        <input [formControl]="registerLoanPromiseForm.controls.dateOfIssue"
               [matDatepicker]="pickerDateOfIssue"
               matInput>
        <mat-datepicker-toggle [for]="pickerDateOfIssue"
                               matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickerDateOfIssue></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Förfallodag</mat-label>
        <input [formControl]="registerLoanPromiseForm.controls.validThru"
               [matDatepicker]="pickerValidThru" matInput>
        <mat-datepicker-toggle [for]="pickerValidThru"
                               matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickerValidThru></mat-datepicker>
      </mat-form-field>
    </div>

    @if (isPrivate) {
      <div>
        <div class="input-holder">
          <mat-checkbox
            color="primary"
            id="bostadrattCheckbox"
            ngDefaultControl
            labelPosition="after"
            (change)="registerLoanPromiseForm.controls.property.controls.type.setValue($event.checked ? 'bostadsratt' : null)"
          >Bostadsrätt
          </mat-checkbox>
        </div>

        @if (registerLoanPromiseForm.controls.property.controls.type.value === 'bostadsratt') {
          <mat-form-field>
            <mat-label>Bostadsrätt max avgift</mat-label>
            <input
              [formControl]="registerLoanPromiseForm.controls.property.controls.maxFee"
              matInput spbFormatNumber>
          </mat-form-field>
        }
      </div>
    }

    @if (registerLoanPromiseForm.controls.type.value === 'company') {
      <div>
        <mat-form-field>
          <mat-label>Ändamål</mat-label>
          <input [formControl]="registerLoanPromiseForm.controls.purpose"
                 matInput ngDefaultControl>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Limitavgift</mat-label>
          <input [formControl]="registerLoanPromiseForm.controls.limitFee"
                 matInput spbFormatNumber>
        </mat-form-field>

        <mat-checkbox
          [formControl]="registerLoanPromiseForm.controls.unconfirmed"
          color="primary">Obekräftad
        </mat-checkbox>
      </div>
    }

    <div class="input-holder">
      <mat-form-field>
        <mat-label>Beviljat belopp</mat-label>
        <input [formControl]="registerLoanPromiseForm.controls.approvedAmount"
               matInput spbFormatNumber>
      </mat-form-field>

      @if (!isPrivate && registerLoanPromiseForm.controls.type.value) {
        <mat-form-field>
          <mat-label>Utnyttjat belopp</mat-label>
          <input [formControl]="registerLoanPromiseForm.controls.usedAmount"
                 matInput spbFormatNumber>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Kvarstående belopp</mat-label>
          <input
            [formControl]="registerLoanPromiseForm.controls.remainingAmount"
            matInput spbFormatNumber>
        </mat-form-field>
      }
    </div>

    <div>
      <mat-form-field class="message">
        <mat-label>Övrigt</mat-label>
        <textarea
          [formControl]="registerLoanPromiseForm.controls.otherInfo"
          matInput
          ngDefaultControl
          rows="3"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button (click)="save()"
          [disabled]="!registerLoanPromiseForm.valid" color="primary"
          mat-raised-button>Spara
  </button>
  <button [mat-dialog-close]="undefined" mat-raised-button>Stäng</button>
</div>