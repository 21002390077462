import {HttpClient} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {IPkg} from '@sparbanken-syd/user-documents-backend'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserDocumentsService {

  private http = inject(HttpClient)

  public getPackage(userId: string): Observable<IPkg> {
    const url = `${environment.userDoc}/admin/packages/find`
    return this.http.put<IPkg>(url, userId)
  }

  public getAllPackages(subs: string[]): Observable<string[]> {
    const url = `${environment.userDoc}/admin/packages`
    return this.http.put<string[]>(url, subs)
  }
}
