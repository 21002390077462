import {Pipe, PipeTransform} from '@angular/core'
import {LoanType} from '@sparbanken-syd/loan-backend'
import {LoanTypeMap} from '@sparbanken-syd/loan-backend/dist/shared'

@Pipe({
  name: 'loanType'
})
export class LoanTypePipe implements PipeTransform {

  transform(value: LoanType): string {
    return LoanTypeMap.get(value) as string
  }

}
