<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Statistik
    </mat-panel-title>
    <mat-panel-description>
      Totalt utfärdade
      lånelöften:&nbsp;<strong>{{ total | number:'1.0-0':'fr' }}</strong>&nbsp;miljoner
      kr
    </mat-panel-description>
  </mat-expansion-panel-header>
  <mat-tab-group>
    @for (tab of tabArray; track $index) {
      <mat-tab [label]="tab.title">
        <spb-table [data]="this[tab.data]" [range]="tab.range"></spb-table>
      </mat-tab>
    }
  </mat-tab-group>
  <div>
  </div>
</mat-expansion-panel>
