import {Component, OnDestroy, OnInit} from '@angular/core'
import {IApplication} from '@sparbanken-syd/loan-backend'
import {filter, Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {KalpUtils} from '../../../utils/kalp.utils'
import {KalpItemComponent} from '../kalp-item/kalp-item.component'
import {DebtQuota} from '../types'

@Component({
  selector: 'spb-kalp-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['../kalp.component.scss'],
  imports: [KalpItemComponent]
})
export class DebtComponent implements OnInit, OnDestroy {
  public loanPromiseData!: IApplication
  public debtQuota: DebtQuota = {} as any
  private sub$ = new Subscription()

  constructor(private loanService: LoanService) {
  }

  public ngOnInit() {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe((value) => {
        this.loanPromiseData = value
        this.debtQuota = KalpUtils.CalculateDebtQuota(this.loanPromiseData)
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
