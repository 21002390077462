import {IApplication, LLApplicant, LoanType} from '@sparbanken-syd/loan-backend'
import {DebtQuota} from '../admin/kalp/types'

export const EditableFields = ['studyLoan', 'loans', 'price', 'downPayment'] as const
export type TEditableField = typeof EditableFields[number]

export class KalpUtils {
  /**
   * Some additional calculations on Debt Quota (Skuldkvot)
   */
  public static CalculateDebtQuota(loanPromise: IApplication): DebtQuota {
    const grossIncome = loanPromise.applicants.reduce((sum: number, applicant: LLApplicant) => {
      const income = applicant.income as number
      return sum + income * 12
    }, 0)
    const additionalLoans = loanPromise.loans!
    let totalLoanBurden = additionalLoans.reduce((sum: number, loan) => {
      if (loan.type === LoanType.HOUSING) {
        return sum + Number(loan.amount)
      }
      return sum
    }, 0)
    totalLoanBurden += loanPromise.property.loanAmount!

    const childrenContribution = (loanPromise.childrenBenefit! + loanPromise.childrenBenefitExtra!) * 12

    const totalIncome = grossIncome + childrenContribution

    const debtQuota = totalLoanBurden / totalIncome * 100

    return {
      totalIncome,
      grossIncome,
      totalLoanBurden,
      debtQuota,
      childrenContribution
    }
  }

  public static GetFieldName(field: TEditableField): string {
    const map: Record<TEditableField, string> = {
      loans: 'lån',
      studyLoan: 'studielån (kronor/månad)',
      price: 'tänkt pris',
      downPayment: 'kontantinsats'
    }
    return map[field]
  }

  public static AddMockedUc(application: IApplication) {
    application.admin = true
    application.applicants.forEach(a => {
      // Add UC data only if the applicant doesn't have it already
      if (!a.uc || !a.uc.applicantName) {
        a.uc = {
          applicantName: 'Dario Pretty Mocked',
          income: ['750000', '480000'],
          countyCode: '12',
          municipalityCode: '12',
          decision: 'J',
          reasons: [],
          status: 'complete',
          incomeDiff: 0
        }
      }
    })
  }
}