@if (!status.complete && !status.legacyManual) {
  <div class="non-superfast-holder">
    @if (status.permanentlyDenied) {
      <!-- are there other reasons? -->
      <h3>Denna ansökan kan inte godkännas eftersom den är nekad av UC.</h3>
    } @else {
      @if (status.canBeIssued) {
        <h3>Ansökan kan nu beviljas.</h3>
      } @else if (status.declined) {
        <div class="text">
          Handläggare har avslagit ansökan.
        </div>
      } @else {
        <h3>Ansökan kan inte beviljas per automatik på grund av:</h3>
        @if (status.needsBetterKalp) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              KALP blir negativ. Du kan i samråd med kund göra ändringar i
              ansökningsuppgifterna, t.ex. räkna på en lägre köpeskilling.
            </div>
          </div>
        }
        @if (status.needsUCAboveThreshold) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har en inkomst från UC som är lägre
              än {{ ucLowestThreshold | number }}  kr
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'ucThreshold'}"></ng-container>
          </div>
        }
        @if (status.needsPropertyLoansMatchingUC) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har angett bostadslån som är avvikande mot UC
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'propertyLoansMatchingUC'}"></ng-container>
          </div>
        }
        @if (status.needsBlancoLoansThreshold) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har blancolån från UC som
              överstiger {{ blancoHighestThreshold | number }}  kr
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'blancoLoansThreshold'}"></ng-container>
          </div>
        }
        @if (status.needsBlancoLoansMatchingUC) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har angett blancolån som är avvikande mot UC
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'blancoLoansMatchingUC'}"></ng-container>
          </div>
        }
        @if (status.needsIncomeCheck) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har uppgivit högre inkomst än UC
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'income'}"></ng-container>

          </div>
        }
        @if (status.needsOccupationCheck) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Sökanden har fel anställningsform
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'occupation'}"></ng-container>

          </div>
        }
        @if (status.needsPropertyCheck) {
          <div class="row">
            <mat-icon class="material-symbols-outlined bullet">dangerous
            </mat-icon>
            <div class="text">
              Status för existerande bostad "Avser att sälja", kräver
              ytterligare manuell hantering.
            </div>
            <ng-container
              *ngTemplateOutlet="action; context: {$implicit :'oldProperty'}"></ng-container>
          </div>
        }
      }
      @if (status.overrides.length > 0) {
        <h4>Följande har åtgärdats</h4>
      }
      @for (override of status.overrides; track $index) {
        <div class="row">
          <mat-icon class="material-symbols-outlined bullet">dangerous
          </mat-icon>
          @if (override === 'ucIncome') {
            <div class="text">
              Inkomsten har kontrollerats.
            </div>
          }
          @if (override === 'oldProperty') {
            <div class="text">
              Bostad som ska säljas är kommenterad.
            </div>
          }
          @if (override === 'occupation') {
            <div class="text">
              Anställningsform är kommenterad.
            </div>
          }
          @if (override === 'ucThreshold') {
            <div class="text">
              UC under {{ ucLowestThreshold | number }} kr har kontrollerats.
            </div>
          }
          @if (override === 'propertyLoansMatchingUC') {
            <div class="text">
              Bostadslån som är avvikande mot UC har kontrollerats.
            </div>
          }
          @if (override === 'blancoLoansThreshold') {
            <div class="text">
              Blancolån över {{ blancoHighestThreshold | number }} kr har
              kontrollerats.
            </div>
          }
          @if (override === 'blancoLoansMatchingUC') {
            <div class="text">
              Blancolån som är avvikande mot UC har kontrollerats.
            </div>
          }
          <ng-container *ngTemplateOutlet="action; context:
        {$implicit :status.overrideToNote(override)}"></ng-container>
        </div>
      }
    }
  </div>
} @else if (status.legacyManual) {
  <h3>Denna ansökan är gjord utan BankID och kan inte gå vidare utan UC</h3>
}

<ng-template #action let-input>
  @if (configService.isAdmin$()) {
    <mat-icon class="material-symbols-outlined action"
              svgIcon="edit_notes"
              matTooltip="Hantera"
              (click)="openNotesDialog(input)"></mat-icon>
  }
</ng-template>
