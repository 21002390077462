import {Component, Input} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {MatTooltip} from '@angular/material/tooltip'
import {IApplication, IApplicationSummary} from '@sparbanken-syd/loan-backend'

@Component({
  selector: 'spb-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  imports: [MatIcon, MatTooltip]
})
export class StatusIndicatorComponent {
  @Input({required: true}) loanPromise: IApplicationSummary | IApplication = {} as any
}
