<h3 mat-dialog-title>Välj ansvarig</h3>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Ansvarig</mat-label>
    <mat-select [formControl]="selectDelegate">
      <mat-option value=""></mat-option>

      @for (user of loanService.users$ | async; track $index) {
        <mat-option [value]="user.name">
          {{ user.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  <button (click)="save()" [disabled]="(currentAssigneeValue === selectDelegate.value) && (currentOfficeValue === selectOffice.value)"
          color="primary"
          mat-raised-button>
    Spara
  </button>
</mat-dialog-actions>