import {Pipe, PipeTransform} from '@angular/core'

const responseMap = new Map<'J' | 'N', string>([
  ['J', 'Godkänd'],
  ['N', 'Avvisad']
])

@Pipe({
  name: 'ucDecision'
})
export class UcDecisionPipe implements PipeTransform {
  transform(value: 'J' | 'N'): string {
    return responseMap.get(value) as string
  }
}
