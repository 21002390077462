<div mat-dialog-title>Ta bort lånelöfte?</div>

<div class="dialog-content-holder">
  <span class="delete-title">
    Är du säker på att du vill ta bort lånelöfte för {{ data.name }}?
  </span>

  <span class="delete-warning">OBS! Åtgärden kan inte ångras</span>
</div>

<mat-dialog-actions class="dialog-actions-holder">
  <button mat-dialog-close="" mat-raised-button>Nej</button>
  <button [mat-dialog-close]="data.promiseId" color="primary" mat-raised-button>
    Ja
  </button>
</mat-dialog-actions>




