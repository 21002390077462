@if (loanPromiseData.oldProperty && (loanPromiseData.oldProperty.type !== -1
  || loanPromiseData.oldProperty.futurePropertyOwnership === 'confirmedContract')) {
  <h3 class="title">Existerande bostad</h3>

  @if (loanPromiseData.oldProperty.futurePropertyOwnership) {
    <p>
      Kund har svarat:
      {{ OldPropertyStatusMap.get(loanPromiseData.oldProperty.futurePropertyOwnership) + '.' || 'FEL: Inget svar från kund' }}

      @if (propertiesWithoutKalp
        .includes(loanPromiseData.oldProperty.futurePropertyOwnership)) {
        <p>
          Kostnader kopplade till befintlig bostad medräknas inte i
          KALP-resultat.
        </p>
      }
    </p>
  }

  <div class="group-holder">
    <div class="subgroup-holder">
      @if (loanPromiseData.oldProperty.type >= 0) {
        <spb-kalp-item
          [textValue]="true"
          text="Typ av bostad"
          [value]="loanPromiseData.oldProperty.type | propertyType"></spb-kalp-item>
      }
      <spb-kalp-item
        format="1.0-0"
        text="Totala lånebeloppet på lån mot bostaden"
        [value]="loanPromiseData.oldProperty.loanAmount"></spb-kalp-item>
      @if (loanPromiseData.oldProperty.price) {
        <spb-kalp-item
          format="1.0-0"
          [text]="loanPromiseData.oldProperty.futurePropertyOwnership === 'confirmedContract' ?
        'Försäljningspris' : 'Uppskattat försäljningspris'"
          [value]="loanPromiseData.oldProperty.price"></spb-kalp-item>
      }
      <spb-kalp-item
        format="1.0-0"
        text="Driftskostnad (kr/mån)"
        [value]="loanPromiseData.oldProperty.runCost"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Fastighetsavgift (kr/mån)"
        [value]="loanPromiseData.oldProperty.tax"></spb-kalp-item>
      @if (loanPromiseData.oldProperty.type === 2) {
        <spb-kalp-item
          description="endast om bostadsrätt"
          format="1.0-0"
          text="Avgift (kr/mån)"
          [value]="loanPromiseData.oldProperty.fee"></spb-kalp-item>
      }
      @if (loanPromiseData.oldProperty.type === 3) {
        <spb-kalp-item
          description="endast om hyressrätt"
          format="1.0-0"
          text="Hyra (kr/mån)"
          [value]="loanPromiseData.oldProperty.fee"></spb-kalp-item>
      }
    </div>
  </div>
}