import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {ActivatedRoute, ParamMap, RouterLink} from '@angular/router'
import {APPLICATION_ID, IApplication} from '@sparbanken-syd/loan-backend'
import {filter, Subscription, switchMap} from 'rxjs'
import {
  StatusIndicatorComponent
} from '../../components/status-indicator/status-indicator.component'
import {ConfigService} from '../../services/config.service'
import {LoanService} from '../../services/loan.service'
import {BasicInfoComponent} from './0-basic-info/basic-info.component'
import {DebtComponent} from './1-debt/debt.component'
import {NewPropertyComponent} from './2-new-property/new-property.component'
import {LoanCostsComponent} from './3-loan-costs/loan-costs.component'
import {OldPropertyComponent} from './4-old-property/old-property.component'
import {TaxReductionComponent} from './5-tax-reduction/tax-reduction.component'
import {KalpInfoComponent} from './6-kalp-info/kalp-info.component'
import {UcInfoComponent} from './7-uc-info/uc-info.component'
import {
  AddNotesDialogData,
  KalpAddNotesComponent
} from './kalp-add-notes/kalp-add-notes.component'
import {KalpEditingComponent} from './kalp-editing/kalp-editing.component'
import {
  KalpIssueLoanComponent
} from './kalp-issue-loan/kalp-issue-loan.component'
import {KalpLogsComponent} from './kalp-logs/kalp-logs.component'
import {
  KalpNonSuperfastInfoComponent
} from './kalp-non-superfast-info/kalp-non-superfast-info.component'
import {
  KalpStatusProgressComponent
} from './kalp-status-progress/kalp-status-progress.component'
import {KalpUcResultComponent} from './kalp-uc-result/kalp-uc-result.component'

@Component({
  selector: 'spb-kalp',
  templateUrl: './kalp.component.html',
  styleUrls: ['./kalp.component.scss'],
  imports: [MatButton, RouterLink, MatIcon, StatusIndicatorComponent, KalpNonSuperfastInfoComponent, KalpStatusProgressComponent, KalpLogsComponent, BasicInfoComponent, DebtComponent, NewPropertyComponent, LoanCostsComponent, OldPropertyComponent, TaxReductionComponent, KalpInfoComponent, UcInfoComponent]
})
export class KalpComponent implements OnInit, OnDestroy {

  public loanPromiseData: IApplication | null = null

  private loanId = ''
  private sub$ = new Subscription()


  constructor(
    public dialog: MatDialog,
    protected configService: ConfigService,
    private loanService: LoanService,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.loanId = params.get('id') as string
          return this.loanService.fetchComplete(this.loanId)
        }),
        switchMap(() => this.loanService.application$)
      )
      .subscribe({
        next: a => {
          this.loanPromiseData = a
        }
      })
  }

  public startEditing() {
    this.dialog
      .open(KalpEditingComponent, {
        disableClose: true,
        width: '600px'
      })
  }

  public reRunUc() {
    this.loanService.runUc(this.loanId).subscribe({
      next: () => {
        this.dialog.open(KalpUcResultComponent)
      }
    })
  }

  public declineLoan() {
    const ref = this.dialog.open(KalpIssueLoanComponent, {
      data: {type: 'Avslå', autoComment: 'Lånelöftet har fått avslag'},
      disableClose: true
    })
    ref.afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: (notes: string) => {
          this.loanService.declineLoan(this.loanId, notes).subscribe({
            next: () => {
              ref.close()
            }
          })
        }
      })

  }

  public issueLoan() {
    const ref = this.dialog.open(KalpIssueLoanComponent, {
      data: {type: 'Bevilja', autoComment: 'Lånelöfte beviljat'},
      disableClose: true
    })
    ref.afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: (notes: string) => {
          this.loanService.issueLoan(this.loanId, notes).subscribe({
            next: () => {
              ref.close()
            }
          })
        }
      })
  }

  public addNote() {
    this.dialog.open<KalpAddNotesComponent, AddNotesDialogData, any>(KalpAddNotesComponent, {
      disableClose: true,
      data: {
        applicationId: this.loanId,
        logType: 'note',
        resolved: false,
        previousNotes: []
      }
    })
  }

  public openDocument() {
    this.loanService.fetchLoanDocument(this.loanPromiseData![APPLICATION_ID])
      .subscribe({
        next: url => window.open(url, '_blank')
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

}
