import {Component, OnDestroy, OnInit} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {IApplicationLog} from '@sparbanken-syd/loan-backend'
import {filter, Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {NoteItemComponent} from './note-item/note-item.component'

@Component({
  selector: 'spb-kalp-logs',
  templateUrl: './kalp-logs.component.html',
  styleUrls: ['./kalp-logs.component.scss'],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, NoteItemComponent]
})
export class KalpLogsComponent implements OnInit, OnDestroy {
  public numberOfChanges = 0

  public notesLogs: IApplicationLog[] = []
  public changesLog: IApplicationLog[] = []

  private sub$ = new Subscription()

  constructor(private loanService: LoanService) {
  }

  ngOnInit() {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe(application => {
        // Sort by date, the oldest on top
        const logs = application.logs || []
        logs.sort((a, b) => b.date - a.date)

        // Differentiate between notes and changes
        this.notesLogs = logs.filter(l => l.type !== 'change')
        this.changesLog = logs.filter(l => l.type === 'change')

        // Calculate number of changes
        this.numberOfChanges = this.changesLog.length
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
