import {DecimalPipe, NgTemplateOutlet} from '@angular/common'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {MatTooltip} from '@angular/material/tooltip'
import {
  APPLICATION_ID,
  IApplication,
  TApplicationLogType
} from '@sparbanken-syd/loan-backend'
import {
  blancoHighestThreshold,
  ucLowestThreshold
} from '@sparbanken-syd/loan-backend/dist/shared'
import {filter, Subscription} from 'rxjs'
import {ConfigService} from '../../../services/config.service'
import {LoanPromiseStatus} from '../../../services/loan-promise-status'
import {LoanService} from '../../../services/loan.service'
import {
  AddNotesDialogData,
  KalpAddNotesComponent
} from '../kalp-add-notes/kalp-add-notes.component'

@Component({
  selector: 'spb-kalp-non-superfast-info',
  templateUrl: './kalp-non-superfast-info.component.html',
  styleUrls: ['./kalp-non-superfast-info.component.scss'],
  imports: [MatIcon, NgTemplateOutlet, MatTooltip, DecimalPipe]
})
export class KalpNonSuperfastInfoComponent implements OnInit, OnDestroy {

  public status: LoanPromiseStatus = new LoanPromiseStatus({} as any)
  protected readonly ucLowestThreshold = ucLowestThreshold
  protected readonly blancoHighestThreshold = blancoHighestThreshold
  private application: IApplication = {} as IApplication
  private sub$ = new Subscription()
  private loanService = inject(LoanService)
  private dialog = inject(MatDialog)

  constructor(protected configService: ConfigService) {
  }

  public ngOnInit() {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe((application) => {
        this.application = application
        this.status = new LoanPromiseStatus(this.application)
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public openNotesDialog(type: TApplicationLogType) {
    this.dialog.open<KalpAddNotesComponent, AddNotesDialogData, any>(KalpAddNotesComponent, {
      disableClose: true,
      data: {
        applicationId: this.application[APPLICATION_ID],
        logType: type,
        previousNotes: [],
        resolved: this.status.hasOverride(type)
      }
    })
  }
}
