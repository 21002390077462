<div mat-dialog-title>Hitta dokument</div>
<div class="content">
  <mat-form-field>
    <input (keydown.enter)="findPackage()"
           [formControl]="sub"
           matInput placeholder="Sök personnummer"
           spbPersonnummerValidator>
  </mat-form-field>
</div>

<mat-dialog-actions>
  <button mat-dialog-close="cancel" mat-raised-button>Stäng</button>
  <button (click)="findPackage()" [disabled]="sub.invalid" color="primary"
          mat-raised-button>Sök
  </button>
</mat-dialog-actions>