<div class="spb-holder">
  @if (configService.isAdmin$()) {
    <div class="statistics">
      <spb-statistics></spb-statistics>
    </div>

    <div class="mat-typography menu-holder">
      <button (click)="viewType = 'contact'" color="primary" mat-raised-button>
        Kontakt
      </button>
      <button (click)="viewType = 'list'" color="primary" mat-raised-button>
        Lista
      </button>
      <button (click)="viewType = 'register'" color="primary" mat-raised-button>
        Registrera
      </button>
    </div>
  }
  <spb-promise-list [view]="viewType"></spb-promise-list>
</div>