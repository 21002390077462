import {Component} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {verify} from '@sparbanken-syd/personnummer'
import {UserDocumentsService} from '../../../services/user-documents.service'
import {
  ShowDocumentsComponent
} from '../show-documents/show-documents.component'

@Component({
  selector: 'spb-find-documents',
  templateUrl: './find-documents.component.html',
  styleUrl: './find-documents.component.scss',
  imports: [MatDialogTitle, MatFormField, MatInput, ReactiveFormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class FindDocumentsComponent {
  public sub = new FormControl<string>('',
    {nonNullable: true, validators: Validators.required})

  constructor(
    public dialog: MatDialog,
    private userDocumentsService: UserDocumentsService
  ) {
  }

  public findPackage() {
    /**
     * Extra check for KeyDown event.
     */
    if (this.sub.invalid) {
      return
    }

    this.userDocumentsService
      .getPackage(verify(this.sub.value)[12]).subscribe({
      next: document => {
        this.dialog.open(ShowDocumentsComponent, {
          data: {
            documents: document.savedDocuments,
            sub: verify(this.sub.value)[12]
          }
        })
      }
    })
  }
}
