@if (loanPromiseData.property) {
  <h3 class="title">Bostaden</h3>

  <!--  1737035100000 is when we implemented this <p> tag...-->
    <!-- loanPromiseData.dateOfIssue > 1737035100000 (2025-01-16 14:45) && can be removed 2025-07-16-->
  @if (loanPromiseData.dateOfIssue > 1737035100000 &&
  propertiesWithDoubleKalpAndApprovedWithoutOldProperty.includes(loanPromiseData.oldProperty.futurePropertyOwnership!)) {
    <p>
      Observera att KALP för den nya bostaden baseras på ett lån utifrån 'tänkt pris' istället för 'lånebelopp'
    </p>
  }

  <div class="group-holder">
    <div class="subgroup-holder">
      <spb-kalp-item
        [textValue]="true"
        text="Typ"
        [value]="loanPromiseData.property.type | propertyType"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Tänkt pris"
        [value]="loanPromiseData.property.price"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Lånebelopp"
        [value]="loanPromiseData.property.loanAmount"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Kontantinsats"
        [value]="loanPromiseData.property.downPayment"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        suffix="%"
        text="Kontantinsats (%)"
        [value]="loanPromiseData.property.downPayment / loanPromiseData.property.price * 100"></spb-kalp-item>
      @if (loanPromiseData.property.type === 2) {
        <spb-kalp-item
          format="1.0-0"
          text="Avgift"
          [value]="loanPromiseData.property.fee"></spb-kalp-item>
      }
      @if (loanPromiseData.property.type !== 2) {
        <spb-kalp-item
          format="1.0-0"
          text="Fastighetsskatt (kr/mån)"
          [value]="loanPromiseData.property.propertyTax"></spb-kalp-item>
      }
      <spb-kalp-item
        format="1.0-0"
        text="Drift"
        [value]="loanPromiseData.property.runCost"></spb-kalp-item>
      <spb-kalp-item
        format="1.2-2"
        text="Ränta"
        [value]="loanPromiseData.property.interestPercent! * 100"
        suffix="%"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Ränta (kr/mån)"
        [value]="loanPromiseData.property.interestSumMonth"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Amortering (kr/mån)"
        [value]="loanPromiseData.property.mortgage"></spb-kalp-item>
      <spb-kalp-item
        format="1.0-0"
        text="Amortering (%)"
        [value]="loanPromiseData.property.mortgagePercent! * 100"></spb-kalp-item>
    </div>
  </div>
}