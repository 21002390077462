import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {Component, Inject} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'

@Component({
  selector: 'spb-kalp-issue-loan',
  templateUrl: './kalp-issue-loan.component.html',
  styleUrl: './kalp-issue-loan.component.scss',
  imports: [MatDialogTitle, MatFormField, MatInput, CdkTextareaAutosize, ReactiveFormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class KalpIssueLoanComponent {
  public notes = new FormControl<string | null>(null,
    {nonNullable: true, validators: [Validators.required]})

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string, autoComment: string }
  ) {
  }
}
