import {inject, Type} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {of, switchMap, tap} from 'rxjs'
import {
  IWaitComponentData,
  WaitComponent
} from '../components/wait/wait.component'

export const Wait = (title = 'Vänta', text = '', errorText = '') => {
  return (target: ServiceBase, key: string, descriptor: PropertyDescriptor) => {
    // We need to preserve a reference to the original
    const original = descriptor.value

    function replaceFunction(this: Type<ServiceBase>, ...args: any[]) {
      // Remember the reference so that we can close
      let ref: MatDialogRef<any>
      // We must return an observable...
      return of([]).pipe(
        tap(() => {
          // Open the dialog.
          ref = target.openDialog.apply(this, [title, text])
        }),
        // Call the actual function.
        switchMap(() => original.apply(this, args)),
        // Close the dialog.
        tap({
          next: () => {
            ref.close()
          },
          error: () => {
            // Set a new title with an error message in the open dialog
            ref.componentInstance.data = {
              title: 'OBS!',
              text: errorText || 'Ett fel har uppstått'
            }

            // Close dialog after 2 seconds
            setTimeout(() => {
              ref.close()
            }, 2000)
          }
        })
      )
    }

    descriptor.value = replaceFunction
    return descriptor
  }
}


export class ServiceBase {
  private dialog: MatDialog = inject(MatDialog)

  public openDialog(title: string, text: string): MatDialogRef<any> {
    return this.dialog.open<WaitComponent, IWaitComponentData, null>(WaitComponent, {
      disableClose: true,
      minWidth: '350px',
      /**
       * If operation take less than approx 200ms it will hardly show.
       */
      enterAnimationDuration: 1000,
      data: {title, text}
    })
  }
}
