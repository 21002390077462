import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {DecimalPipe} from '@angular/common'
import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {
  IApplicationLog,
  TApplicationLogType
} from '@sparbanken-syd/loan-backend'
import {
  blancoHighestThreshold,
  ucLowestThreshold
} from '@sparbanken-syd/loan-backend/dist/shared'
import {LoanService} from '../../../services/loan.service'
import {
  KalpTypeIndicatorComponent
} from '../kalp-logs/kalp-type-indicator/kalp-type-indicator.component'

export interface AddNotesDialogData {
  applicationId: string
  logType: TApplicationLogType
  previousNotes: string[],
  resolved: boolean
}

const nonResolvableLogs: TApplicationLogType[] = ['note', 'issue', 'change']

@Component({
  selector: 'spb-kalp-add-notes',
  templateUrl: './kalp-add-notes.component.html',
  styleUrl: './kalp-add-notes.component.scss',
  imports: [MatDialogTitle, KalpTypeIndicatorComponent, MatCheckbox, ReactiveFormsModule, MatFormField, MatInput, CdkTextareaAutosize, MatDialogActions, MatButton, MatDialogClose, DecimalPipe]
})
export class KalpAddNotesComponent implements OnInit {
  public notes = new FormControl<string | null>(null,
    {nonNullable: true, validators: [Validators.required]})

  public resolved = new FormControl<boolean>(false, {nonNullable: true})

  public resolvable = false

  protected readonly ucLowestThreshold = ucLowestThreshold
  protected readonly blancoHighestThreshold = blancoHighestThreshold

  constructor(
    private loanService: LoanService,
    private dialogRef: MatDialogRef<KalpAddNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddNotesDialogData
  ) {
  }

  ngOnInit() {
    // If previously resolved keep that
    this.resolvable = nonResolvableLogs.indexOf(this.data.logType) === -1
    this.resolved.setValue(this.data.resolved)
  }

  public save() {
    const applicationLog: IApplicationLog = {
      date: Date.now(),
      type: this.data.logType,
      notes: this.notes.value as string,
      changes: [],
      user: '',
      override: this.resolved.value
    }

    // Add notes
    this.loanService.addNotes(this.data.applicationId, applicationLog)
      .subscribe({
        next: () => {
          this.dialogRef.close()
        }
      })
  }
}
