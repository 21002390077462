<h2>Lånelöften</h2>
@if (configService.isAdmin$()) {
  <p>Listan nedan är alla lånelöften som fortfarande är aktiva. Klicka på ett
    lånelöfte för att se detaljer</p>
  <button (click)="update()" class="button" color="primary" mat-raised-button>
    Uppdatera
  </button>
  <div class="holder">
    <mat-form-field floatLabel="always">
      <input [formControl]="filterCtrl" matInput placeholder="Filtrera">
    </mat-form-field>
  </div>
} @else {
  <p>Här kan du söka efter aktiva lånelöften. Efter sökningen kan du klicka på
    ett lånelöfte för att visa detaljer.</p>
  <div class="holder">
    <mat-form-field floatLabel="always" [style.max-width.px]="200">
      <input [formControl]="readonlySub" spbPersonnummerValidator matInput
             placeholder="Sök personnummer"
             (keydown.enter)="readonlySub.value && readonlySub.valid && getReadOnly()"
      >
    </mat-form-field>
    <button
      [disabled]="readonlySub.value && !readonlySub.valid"
      mat-raised-button (click)="getReadOnly()">Sök
    </button>
  </div>
}

<table [dataSource]="loanPromiseList" class="mat-elevation-z8" mat-table
       matSort>
  <caption>List of Loanpromises</caption>
  <ng-container matColumnDef="dateOfIssue">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Datum</th>
    <td *matCellDef="let loanPromise"
        mat-cell> {{ loanPromise.dateOfIssue | date:'yyyy-MM-dd' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="timeOfIssue">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">
      Klockslag
    </th>
    <td *matCellDef="let loanPromise"
        mat-cell> {{ loanPromise.dateOfIssue | date:'HH:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="applicantName">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Namn</th>
    <td *matCellDef="let loanPromise"
        [routerLink]="['/admin/promise', loanPromise['loan-promise-id']]"
        class="clickable"
        mat-cell> {{ loanPromise.applicantName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="applicantFull">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Namn</th>
    <td *matCellDef="let loanPromise"
        [routerLink]="['/admin/promise', loanPromise['loan-promise-id']]"
        class="clickable"
        mat-cell>
      <span>{{ loanPromise.applicantName }}</span><br>
      <span class="sub-text">{{ loanPromise.applicantId }}</span></td>
  </ng-container>

  <ng-container matColumnDef="coApplicantFull">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">
      Medsökande
    </th>
    <td *matCellDef="let loanPromise"
        [routerLink]="['/admin/promise', loanPromise['loan-promise-id']]"
        mat-cell>
      <span>{{ loanPromise.coApplicantName }}</span><br>
      <span class="sub-text">{{ loanPromise.coApplicantId }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="coApplicantName">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Namn</th>
    <td *matCellDef="let loanPromise"
        mat-cell> {{ loanPromise.coApplicantName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="applicantContact">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Kontakt
    </th>
    <td *matCellDef="let loanPromise"
        [routerLink]="['/admin/promise', loanPromise['loan-promise-id']]"
        class="clickable"
        mat-cell>
      <span>{{ loanPromise.contactPhone }}</span><br>
      <span class="sub-text">{{ loanPromise.contactEmail }}</span></td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Status
    </th>
    <td *matCellDef="let loanPromise" class="icon" mat-cell>
      <spb-status-indicator [loanPromise]="loanPromise"/>
    </td>
  </ng-container>

  <ng-container matColumnDef="approvedAmount">
    <th *matHeaderCellDef class="header-number" mat-header-cell mat-sort-header
        scope="col">Sökt Belopp
    </th>
    <td *matCellDef="let loanPromise" class="number"
        mat-cell> {{ loanPromise.seekedAmount | number:'1.0-0':'fr' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="approvedAmountFull">
    <th *matHeaderCellDef class="header-number" mat-header-cell mat-sort-header
        scope="col">Belopp
    </th>
    <td *matCellDef="let loanPromise" class="number" mat-cell>
      @if (loanPromise) {
        <span>{{ loanPromise.approvedAmount || loanPromise.seekedAmount | number:'1.0-0':'fr' }}</span>
        <br>
        <span
          class="sub-text">Över 75% {{ loanPromise.above75 | number:'1.0-0':'fr' }}</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="kalp">
    <th *matHeaderCellDef class="header-number" mat-header-cell mat-sort-header
        scope="col">KALP
    </th>
    <td *matCellDef="let loanPromise" class="number"
        mat-cell> {{ loanPromise.kalp | number:'1.0-0':'fr' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th *matHeaderCellDef class="center" mat-header-cell scope="col">Ta bort
    </th>
    <td
      (click)="remove(loanPromise['loan-promise-id'], loanPromise.applicantName)"
      *matCellDef="let loanPromise"
      [matTooltip]="'Gallras '+ (loanPromise.timeStamp * 1000 | date: 'yyyy-MM-dd')"
      [style.text-align]="'center'"
      class="delete icon clickable"
      mat-cell
    >
      <mat-icon class="delete">delete_forever</mat-icon>
    </td>
  </ng-container>


  <ng-container matColumnDef="documents">
    <th (click)="findPkg()" *matHeaderCellDef class="center clickable" mat-header-cell
        scope="col">Dokument
    </th>
    <td *matCellDef="let loanPromise" class="center"
        mat-cell
    >
      @if (checkLoanPromise(loanPromise).applicant) {
        <mat-icon
          (click)="getDoc(loanPromise.applicantPersonNummer)"
          class="icon clickable">attachment
        </mat-icon>
      } @else {
        <ng-container [ngTemplateOutlet]="emptyIcon"/>
      }

      @if (checkLoanPromise(loanPromise).coApplicant) {
        <mat-icon (click)="getDoc(loanPromise.coApplicantId)"
                  class="icon clickable">attachment
        </mat-icon>
      } @else {
        <ng-container [ngTemplateOutlet]="emptyIcon"/>
      }

      <ng-template #emptyIcon>
        <mat-icon class="icon"></mat-icon>
      </ng-template>
    </td>
  </ng-container>


  <ng-container matColumnDef="assignee">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">
      Tilldelad
    </th>
    <td *matCellDef="let loanPromise" mat-cell>
      <div class="table-button">
        <button (click)="delegate(loanPromise)"
                [disabled]="!configService.isAdmin$()" mat-raised-button>
          {{ loanPromise.adminData?.assignee || 'Tilldela' }}
        </button>
      </div>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
  <tr *matRowDef="let row; columns: columnsToDisplay"
      mat-row></tr>

  <tr *matNoDataRow class="no-data">
    <td [attr.colspan]="columnsToDisplay.length" class="no-data">
      Inga ansökningar att visa för tillfället...
    </td>
  </tr>
</table>
<mat-paginator [pageSizeOptions]="[20, 50, 100]"
               [pageSize]="20" showFirstLastButtons>
</mat-paginator>
