import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent
} from '@angular/material/dialog'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'

@Component({
  selector: 'spb-uc-report',
  templateUrl: './uc-report.component.html',
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class UcReportComponent {

  public html: SafeHtml = ''

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) htmlAsString: string | undefined
  ) {
    this.html = this.sanitizer.bypassSecurityTrustHtml(htmlAsString ?? `<span>Felaktigt svar från UC</span>`)
  }
}
