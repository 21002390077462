@if (debtQuota) {
  <h3 class="title">Skuldkvot</h3>
  <div class="group-holder">
    <div class="subgroup-holder">
      <spb-kalp-item format="1.0-0" text="Skuldkvot"
                     [value]="debtQuota.debtQuota"></spb-kalp-item>
      <spb-kalp-item format="1.0-0" text="Lånebörda"
                     [value]="debtQuota.totalLoanBurden"></spb-kalp-item>
      <spb-kalp-item format="1.0-0" text="Bruttoinkomst"
                     [value]="debtQuota.grossIncome"></spb-kalp-item>
      <spb-kalp-item format="1.0-0" text="Barnbidrag"
                     [value]="debtQuota.childrenContribution"></spb-kalp-item>
      <spb-kalp-item
        [description]="debtQuota.grossIncome + ' + ' + debtQuota.childrenContribution"
        format="1.0-0"
        [sum]="true" text="Total inkomst"
        [value]="debtQuota.totalIncome"></spb-kalp-item>
    </div>
  </div>
}