<div>
  <table [dataSource]="tableSource" class="mat-elevation-z4" mat-table
         multiTemplateDataRows>
    <caption>List of Weeks</caption>
    <ng-container matColumnDef="day">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell> {{ item.day }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="weekday">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell> {{ item.weekday }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="week">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>{{ item.week }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="month">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>{{ item.month }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="year">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>{{ item.year }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="all">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>{{ item.year }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="age">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>{{ item.age }}</td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="sex">
      <th *matHeaderCellDef mat-header-cell scope="col">{{ rangeTitle }}</th>
      <td *matCellDef="let item" mat-cell>
        @switch (item.key) {
          @case ('FF') {
            <span>&#9792; - &#9792;</span>
          }
          @case ('MF') {
            <span>&#9794; - &#9792;</span>
          }
          @case ('FM') {
            <span>&#9792; - &#9794;</span>
          }
          @case ('MM') {
            <span>&#9794; - &#9794;</span>
          }
          @case ('M') {
            <span>&#9794;</span>
          }
          @case ('F') {
            <span>&#9792;</span>
          }
        }
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Ansökningar
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.total }}</td>
      <td *matFooterCellDef mat-footer-cell>{{ getTotal(undefined, 'total') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="auto_total">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Automatiserade
      </th>
      <td *matCellDef="let item" class="number"
          mat-cell> {{ item.total - item.pending }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.auto | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="approved">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Godkända
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.approved }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.approved | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="rejected">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Avslag
      </th>
      <td *matCellDef="let item" class="number" mat-cell>
        {{ item.rejected }}<span [hidden]="hidePercent"
                                 class="percent"> ({{ item.quotas.rejected | number:'1.0-0':'fr' }}
        %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="pending">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Handläggare
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.pending }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.pending | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="issuable">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Svar direkt
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.issuable }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.issuable | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="uc">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Avslag UC
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.uc }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.uc | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="kalp">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Avslag KALP
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{ item.kalp }}
        <span [hidden]="hidePercent"
              class="percent">({{ item.quotas.kalp | number:'1.0-0':'fr' }}
          %)</span>
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th *matHeaderCellDef class="header-number" mat-header-cell scope="col">
        Beviljat (msek)
      </th>
      <td *matCellDef="let item" class="number" mat-cell>{{
          item.amounts.approved / 1000 /
          1000 | number:'1.0-0':'fr'
        }}
      </td>
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
    <!--suppress AngularUndefinedBinding -->
    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
    <!--suppress AngularUndefinedBinding -->
    <tr *matRowDef="let row; columns: columnsToDisplay"
        mat-row></tr>
  </table>
  <div class="config-holder">
    <mat-checkbox (change)="getTotal($event, 'percent')"
                  [checked]="hidePercent"
                  color="primary">Dölj procent
    </mat-checkbox>
  </div>
</div>
