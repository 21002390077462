import {AsyncPipe, DatePipe, DecimalPipe} from '@angular/common'
import {
  AfterViewInit,
  Component,
  effect,
  Inject,
  OnInit,
  signal,
  ViewChild
} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatOption} from '@angular/material/core'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatPaginator} from '@angular/material/paginator'
import {MatSelect} from '@angular/material/select'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {MatTooltip} from '@angular/material/tooltip'
import {RouterLink} from '@angular/router'
import {
  APPLICATION_ID,
  IApplication,
  IApplicationAdminData,
  IApplicationSummary,
  IRegistration,
  IRegistrationApplicant,
  LLApplicant,
  TApplicationStatus,
  TRegistrationType
} from '@sparbanken-syd/loan-backend'
import {combineLatest, filter, switchMap} from 'rxjs'
import {LOCAL_STORAGE} from '../../../application/app'
import {
  StatusIndicatorComponent
} from '../../../components/status-indicator/status-indicator.component'
import {WaitComponent} from '../../../components/wait/wait.component'
import {LoanService} from '../../../services/loan.service'
import {RegisterService} from '../../../services/register.service'
import {DelegateComponent} from '../../classic/delegate/delegate.component'
import {
  PromiseListDeleteDialogComponent
} from '../../classic/promise-list-delete-dialog/promise-list-delete-dialog.component'
import {
  RegisterLoanPromiseComponent
} from '../register-loan-promise/register-loan-promise.component'

interface IRegistrationListItem {
  [APPLICATION_ID]: string
  dateOfIssue: number
  validThru: number
  usedAmount: number
  status: TApplicationStatus
  approvedAmount: number
  type: TRegistrationType | null
  timeStamp: number
  applicantFull?: string
  applicantId?: string
  coApplicantFull?: string
  coApplicantId?: string
  adminData: IApplicationAdminData
  office?: string
}

interface StoredFilterValues {
  assignee: string | null
  office: string | null
  registerType: TRegistrationType | null
  text: string
  status: TApplicationStatus | 'simpleLoanPromise'
}

const FILTER_VALUE = 'loan-admin-filter'

@Component({
  selector: 'spb-register-list',
  templateUrl: './register-list.component.html',
  styleUrl: './register-list.component.scss',
  imports: [MatButton, MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption, MatInput, RouterLink, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, StatusIndicatorComponent, MatIcon, MatTooltip, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, AsyncPipe, DecimalPipe, DatePipe]
})
export class RegisterListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined
  @ViewChild(MatSort, {static: true}) sort: MatSort | undefined

  public columnsToDisplay = [
    'dateOfIssue',
    'validThru',
    'applicantFull',
    'coApplicantFull',
    'status',
    'approvedAmount',
    'office',
    'assignee',
    'print',
    'delete'
  ]

  public loanPromiseList = signal<IRegistrationListItem[]>([])
  public dataSource = new MatTableDataSource<IRegistrationListItem>([])

  public offices: string[] = []

  public filter = new FormGroup({
    text: new FormControl<string>(''),
    office: new FormControl<string | null>(null),
    assignee: new FormControl<string | null>(null),
    status: new FormControl<TApplicationStatus | 'simpleLoanPromise' | null>(null),
    registerType: new FormControl<TRegistrationType | null>(null)
  })

  // Type guard to check if the input is of type IRegistration
  protected isRegistration = (item: IRegistration | IApplication): item is IRegistration => {
    return item.status === 'registered'
  }
  // Type guard to check if the applicant is of type IRegistrationApplicant
  protected isRegistrationApplicant = (applicant: IRegistrationApplicant | LLApplicant): applicant is IRegistrationApplicant => {
    return 'sub' in applicant
  }
  // Type guard to check if the applicant is of type LLApplicant
  protected isLLApplicant = (applicant: IRegistrationApplicant | LLApplicant): applicant is LLApplicant => {
    return 'personNummer' in applicant
  }

  constructor(
    public dialog: MatDialog,
    protected loanService: LoanService,
    protected registerService: RegisterService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    // Automatically update the table when loanPromiseList changes
    effect(() => {
      this.dataSource.data = this.loanPromiseList()
    })
  }

  public ngOnInit() {
    /**
     * Load loan promises and offices, apply stored filters if available.
     */
    combineLatest([
      this.registerService.getRegistrations().pipe(
        switchMap(() => this.registerService.registeredPromises$)
      ),
      this.loanService.getOffices()
    ]).subscribe({
      next: ([promises, offices]) => {
        // Ensure that all promises have a 'type' property.
        // If a promise is missing the 'type', it is assumed to be a private promise
        // (promises from loan-fe are considered private by default).
        promises.forEach(p => {
          p.type ||= 'private' // Assign 'private' if 'type' is undefined or falsy
        })


        this.loanPromiseList.set(this.toList(promises))
        this.offices = offices.sort((a, b) => a.localeCompare(b))

        // Apply stored filter values if present
        const storedFilterValues = JSON.parse(this.injectedLocalStorage.getItem(FILTER_VALUE) as string)
        if (storedFilterValues) {
          this.filterValues(storedFilterValues)
          this.filter.setValue(storedFilterValues)
        }
      }
    })

    /**
     * Listen for changes in the filter form and apply the filters.
     */
    this.filter.valueChanges.subscribe((filter) => {
      this.filterValues(filter as StoredFilterValues)
      this.saveFilterValues(filter as StoredFilterValues)
    })
  }

  /**
   * Opens the delegate dialog for assigning a delegate to the application.
   */
  public delegate(application: IApplicationSummary): void {
    this.dialog.open(DelegateComponent, {
      data: {
        application,
        offices: this.offices
      },
      disableClose: true,
      width: '400px'
    })
  }

  /**
   * Opens the register dialog for registering a loan promise.
   */
  public register(id?: string) {
    this.dialog.open(RegisterLoanPromiseComponent, {
      data: id
    })
  }

  /**
   * Opens the delete confirmation dialog and removes the selected loan promise.
   */
  public remove(id: string): void {
    const dialogRef = this.dialog.open(PromiseListDeleteDialogComponent, {
      data: {name: '', promiseId: id}
    })

    dialogRef.afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((id: string) => this.registerService.delete(id))
      )
      .subscribe({
        next: () => {
          this.removeLoanPromiseFromList(id)
        },
        error: (err) => {
          console.error('Deletion failed:', err)
        }
      })
  }

  /**
   * Prints the loan document for the given loan promise.
   */
  public print(id: string) {
    const ref = this.dialog.open(WaitComponent, {
      data: {
        title: 'Vänligen vänta...'
      }
    })
    this.registerService.getLoanDocument(id).subscribe({
      next: res => {
        ref.close()
        window.open(res.url, '_blank')
      },
      error: err => {
        ref.close()
        console.error('Document retrieval failed:', err)
      }
    })
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort as MatSort
    this.dataSource.paginator = this.paginator as MatPaginator
  }

  /**
   * Saves the current filter values into local storage.
   */
  private saveFilterValues(filter: StoredFilterValues): void {
    this.injectedLocalStorage.setItem(FILTER_VALUE, JSON.stringify(filter))
  }

  /**
   * Filters the data based on the given filter values.
   */
  private filterValues(storedFV: StoredFilterValues): void {
    const filteredData = this.toList(
      this.registerService.registeredPromises$.value.filter((r: IRegistration) => {
        // If the status is "simpleLoanPromise", include both "approved" and "manually_issued"
        const matchesStatus = storedFV.status === 'simpleLoanPromise'
          ? ['approved', 'manually_issued'].includes(r.status)
          : !storedFV.status || r.status === storedFV.status

        return (!storedFV.assignee || r.adminData?.assignee === storedFV.assignee) &&
          (!storedFV.office || r.adminData?.office === storedFV.office) &&
          (!storedFV.registerType || r.type === storedFV.registerType) &&
          matchesStatus
      })
    )

    this.loanPromiseList.set(filteredData)
    this.dataSource.filter = storedFV.text
  }


  /**
   * Removes a loan promise from the list by filtering it out.
   */
  private removeLoanPromiseFromList(id: string): void {
    const updatedData = this.loanPromiseList().filter(a => a[APPLICATION_ID] !== id)
    this.loanPromiseList.set(updatedData)
  }

  /**
   * Transforms a list of IRegistration and IApplication objects into IRegistrationListItem objects.
   */
  private toList(input: (IRegistration | IApplication)[]): IRegistrationListItem[] {
    return input.map((i: IRegistration | IApplication) => {

      // Create a result object with defaults and shared properties
      const result: IRegistrationListItem = {
        [APPLICATION_ID]: i[APPLICATION_ID],
        approvedAmount: this.isRegistration(i) ? i.approvedAmount : 0,
        usedAmount: this.isRegistration(i) ? i.usedAmount : 0,
        dateOfIssue: i.dateOfIssue ?? 0,
        validThru: i.validThru ?? 0,
        applicantFull: '',
        applicantId: '',
        coApplicantFull: '',
        coApplicantId: '',
        office: i.adminData?.office ?? '',
        status: i.status,
        adminData: i.adminData || {} as IApplicationAdminData,
        type: this.isRegistration(i) ? i.type : 'unknown',
        timeStamp: i.timeStamp ?? 0
      } as any

      // Handle applicant details based on type
      const primaryApplicant = i.applicants?.[0]
      if (primaryApplicant) {
        if (this.isRegistrationApplicant(primaryApplicant)) {
          result.applicantFull = primaryApplicant.name
          result.applicantId = primaryApplicant.sub
        } else if (this.isLLApplicant(primaryApplicant)) {
          result.applicantFull = primaryApplicant.uc?.applicantName
          result.applicantId = primaryApplicant.personNummer
        }
      }

      // Handle co-applicant details if present
      const coApplicant = i.applicants?.[1]
      if (coApplicant) {
        if (this.isRegistrationApplicant(coApplicant)) {
          result.coApplicantFull = coApplicant.name
          result.coApplicantId = coApplicant.sub
        } else if (this.isLLApplicant(coApplicant)) {
          result.coApplicantFull = coApplicant.uc?.applicantName
          result.coApplicantId = coApplicant.personNummer
        }
      }

      return result
    })
  }


}
