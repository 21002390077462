import {formatNumber} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {IApplication} from '@sparbanken-syd/loan-backend'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {search} from 'jmespath'
import {filter, first} from 'rxjs'
import {LoanService} from '../../../../services/loan.service'

@Component({
  selector: 'spb-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrl: './edit-field.component.scss',
  imports: [MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption, MatInput, MatError, FormatNumberDirective]
})
export class EditFieldComponent implements OnInit {
  @Input({required: true}) application!: IApplication
  /**
   * The name of the field to edit.
   */
  @Input() text = ''
  /**
   * The float label.
   */
  @Input({required: true}) label!: string
  /**
   * The form you want to edit, can be one field
   * or many, it cannot be an array.
   */
  @Input({required: true}) control!: FormControl<number | string | null | undefined>
  /**
   * If this is a select field enter the selections
   * as "Name" <value> pairs, Like ['Blanco, 2]
   */
  @Input() select: [string, string | number][] | null = null

  /**
   * The path to update in the application as a JMES path
   * expression
   */
  @Input({required: true}) path!: string

  /**
   * If you have an indexed property add it here, start with
   * one as we will ignore 0. Used in both presentation
   * and change logs.
   */
  @Input() index = 0

  /**
   * Any changes to this field is recorded here.
   */
  public changes: string | null = null

  /**
   * How we present the value after formatting
   */
  protected initialPresentation = ''
  /**
   * We take the initial value from the application exactly
   * as it came to us
   */
  private initialValue: number | string = 0

  /**
   * Keep these for easy access to values.
   */
  private targetProperty = ''
  private targetPath = ''

  constructor(private loanService: LoanService) {
  }

  public ngOnInit(): void {
    this.loanService.application$
      .pipe(filter(Boolean), first())
      .subscribe((originalApplication: IApplication) => {
        this.initialValue = search(originalApplication, this.path)
        this.initialPresentation = this.translate(this.initialValue)

        this.control.setValue(this.initialValue, {emitEvent: false})
        this.control.updateValueAndValidity({emitEvent: true})
        this.control.markAsTouched()
        const parts: string[] = this.path.split('.')
        this.targetProperty = parts.pop() as string
        this.targetPath = parts.join('.')
      })

    this.control.valueChanges.pipe(
      filter((v): v is string | number => v !== null && v !== undefined)
    ).subscribe({
      next: (v) => {
        if (!this.targetPath) {
          this.application[this.targetProperty] = v
        } else {
          search(this.application, this.targetPath)[this.targetProperty] = v
        }
        this.changes = null
        if (v !== this.initialValue) {
          const change = `Ändrar ${this.text} #${this.index} ` +
            `från ${this.initialPresentation || 'manuellt skapad'} till ${this.translate(v)}`
          this.changes = change.replace(/#0 /g, '')
        }
      }
    })
  }

  private translate(input: string | number): string {
    if (this.select) {
      const found: any = this.select.find(v => v[1] === input)
      return found ? found[0] : ''
    } else {
      return formatNumber(
        Number.parseInt(input + '') || 0, 'fr', '1.0-0'
      )
    }
  }
}
