<div class="holder">
  <div class="dialog-field-title">
    <p>
      @if (text) {
        {{ text }}

        <br>
      }
      <span class="subtitle">Aktuellt värde: {{ initialPresentation }}</span>
    </p>
  </div>
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    @if (select) {
      <mat-select
        ngDefaultControl
        placeholder="Välj"
        [formControl]="control">
        @for (s of select; track $index) {
          <mat-option [value]="s[1]">{{ s[0] }}</mat-option>
        }
      </mat-select>
    } @else {
      <input matInput [formControl]="control" spbFormatNumber>
    }
    <mat-error>{{ control.errors?.error }}</mat-error>
  </mat-form-field>
</div>