@if (!configService.isAdmin$() && !configService.isRegistrar$() && !configService.isReadOnly$()) {
  <div>
    <div class="login-holder">
      <div class="text-container mat-elevation-z8 ">
        <p>För att komma igång behöver du först logga in med ditt BankID</p>
        <spb-bankid
          mode="login"
          [bankIdUrl]="bankIdUrl"
          (accessToken)="configService.setToken($event).subscribe()"
          [domain]="domain"
        ></spb-bankid>
      </div>
    </div>
  </div>
} @else {

  <div class="spb-content-holder">
    <div class="select">
      <mat-card>
        <mat-card-header>
          <ng-container>
            <div class="card-header" mat-card-avatar>
              <mat-icon>draw</mat-icon>
            </div>
            <mat-card-title>
              <a class="header-link" [routerLink]="['classic']">Admin</a>
            </mat-card-title>
          </ng-container>
        </mat-card-header>
        <mat-card-content>
          Admin system för hantering av digitala ansökningar.
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <ng-container>
            <div class="card-header" mat-card-avatar>
              <mat-icon>work_history</mat-icon>
            </div>
            <mat-card-title>
              <a class="header-link" [routerLink]="['register']">Registrering
                och översikt</a>
            </mat-card-title>
          </ng-container>
        </mat-card-header>
        <mat-card-content>
          Registrering av lånelöften och limiter som är beviljade i EKP samt
          översikt över samtliga beviljade lånelöften/limiter.
        </mat-card-content>
      </mat-card>
    </div>
  </div>
}
