import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog'
import {UCInfo} from '@sparbanken-syd/loan-backend'
import {filter, Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {UcInfoComponent} from '../7-uc-info/uc-info.component'

@Component({
  selector: 'spb-kalp-uc-result',
  templateUrl: './kalp-uc-result.component.html',
  styleUrls: ['./kalp-uc-result.component.scss'],
  imports: [UcInfoComponent, MatDialogActions, MatButton, MatDialogClose]
})
export class KalpUcResultComponent implements OnInit, OnDestroy {
  private sub$ = new Subscription()

  constructor(
    private loanService: LoanService,
    @Inject(MAT_DIALOG_DATA) public data: UCInfo | undefined
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.loanService.application$
      .pipe(filter(Boolean))
      .subscribe()
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
