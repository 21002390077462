import {Pipe, PipeTransform} from '@angular/core'
import {PropertyType} from '@sparbanken-syd/loan-backend'
import {PropertyTypeMap} from '@sparbanken-syd/loan-backend/dist/shared'

@Pipe({
  name: 'propertyType'
})
export class PropertyTypePipe implements PipeTransform {
  transform(value: PropertyType): string {
    return PropertyTypeMap.get(value) as string
  }

}
