import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'

export interface DialogData {
  promiseId: string
  name: string
}

@Component({
  selector: 'spb-promise-list-delete-dialog',
  templateUrl: 'promise-list-delete-dialog.component.html',
  styleUrl: './promise-list-delete.component.scss',
  imports: [MatDialogTitle, MatDialogActions, MatButton, MatDialogClose]
})
export class PromiseListDeleteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}
