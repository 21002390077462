import {AdminContentComponent} from './classic/admin-content.component'
import {HomeComponent} from './home/home.component'
import {KalpComponent} from './kalp/kalp.component'
import {
  RegisterListComponent
} from './register/register-list/register-list.component'

export default [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'classic',
    component: AdminContentComponent
  },
  {
    path: 'register',
    component: RegisterListComponent
  },
  {
    path: 'promise/:id',
    component: KalpComponent
  }
]

