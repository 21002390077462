import {DecimalPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {MatTab, MatTabGroup} from '@angular/material/tabs'
import {IApplicationSummary} from '@sparbanken-syd/loan-backend'
import {Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {TableComponent} from './table/table.component'

export interface IStatisticsQuotas {
  pending: number,
  approved: number,
  rejected: number,
  auto: number,
  uc: number,
  kalp: number
  issuable: number,
}

export interface IStatisticsAmounts {
  pending: number,
  approved: number,
  rejected: number,
}

export interface IStatistics {
  total: number,
  pending: number,
  approved: number,
  rejected: number,
  issuable: number,
  uc: number
  kalp: number,
  amounts: IStatisticsAmounts,
  quotas: IStatisticsQuotas,
  day: number,
  weekday: number,
  week: number,
  month: number,
  year: number,
  key: string,
  sex: string,
  age: string
}

@Component({
  selector: 'spb-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: [],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatTabGroup, MatTab, TableComponent, DecimalPipe]
})
export class StatisticsComponent implements OnInit, OnDestroy {

  /**
   * Old sum of data
   */
  public total: string | undefined

  public days: IStatistics[] = []
  public weekdays: IStatistics[] = []
  public weeks: IStatistics[] = []
  public months: IStatistics[] = []
  public years: IStatistics[] = []
  public all: IStatistics[] = []
  public sexes: IStatistics[] = []
  public ages: IStatistics[] = []

  public tabArray = [
    {title: 'Vecka', data: 'weeks', range: 'week'},
    {title: 'Månad', data: 'months', range: 'month'},
    {title: 'År', data: 'years', range: 'year'},
    {title: 'Veckodag', data: 'weekdays', range: 'weekday'},
    {title: 'Dag (i månad)', data: 'days', range: 'day'},
    {title: 'Alla', data: 'all', range: 'all'},
    {title: 'Ålder (sökande)', data: 'ages', range: 'age'},
    {title: 'Kön (enl. pers.nr.)', data: 'sexes', range: 'sex'}
  ]

  /**
   * Subscribe to the full list of loan promise list items.
   */
  private IApplicationSummary$ = new Subscription()

  constructor(private loanService: LoanService) {
  }

  private static CalculateAge(item: any): string {
    let age = 0
    // The if is just to safeguard for test.
    if (item.applicantId) {
      const year = Number.parseInt(item.applicantId.substring(0, 4), 10)
      const thisYear = new Date().getFullYear()
      age = thisYear - year
    }
    // Turn the age into a range.
    return (Math.ceil(age / 10) * 10) + ' - ' + ((Math.ceil(age / 10) * 10) + 10)
  }

  public ngOnDestroy(): void {
    this.IApplicationSummary$.unsubscribe()
  }

  // Returns the ISO week of the date.
  public getWeek = (input: any) => {
    const now = new Date(input)
    const oneJan = new Date(now.getFullYear(), 0, 1)
    return Math.ceil((((now.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7)
  }

  private getMonth = (month: any) => {
    const map: any = {
      1: 'Januari',
      2: 'Februari',
      3: 'Mars',
      4: 'April',
      5: 'Maj',
      6: 'Juni',
      7: 'Juli',
      8: 'Augusti',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'December'
    }
    return map[month]
  }

  private getWeekDay = (day: any) => {
    const map: any = {
      1: 'Måndag',
      2: 'Tisdag',
      3: 'Onsdag',
      4: 'Torsdag',
      5: 'Fredag',
      6: 'Lördag',
      7: 'Söndag'
    }
    return map[day]
  }

  public ngOnInit(): void {
    this.IApplicationSummary$ =
      this.loanService.loanPromiseList$.subscribe({
        next: (list: IApplicationSummary[]) => {
          let total = 0
          list
            .filter((item: IApplicationSummary) => item.approvedAmount)
            .forEach((item: IApplicationSummary) => {
              total += item.approvedAmount
            })
          this.total = (total / 1000 / 1000) + ''
          const aggregate = this.aggregateItems(list)
          this.days = this.summarize(aggregate.days)
          this.weekdays = this.summarize(aggregate.weekdays)
          this.all = this.summarize(aggregate.all)
          this.weeks = this.summarize(aggregate.weeks)
          this.months = this.summarize(aggregate.months)
          this.months.forEach((month: IStatistics) => {
            month.month = this.getMonth(month.month)
          })
          this.weekdays.forEach((item: IStatistics) => {
            item.weekday = this.getWeekDay(item.weekday)
          })
          this.years = this.summarize(aggregate.years)
          this.ages = this.summarize(aggregate.ages)
          this.sexes = this.summarize(aggregate.sexes)
        }
      })
  }

  /**
   * Calculates the sex (as derived from personnummer) for the items applicants.
   * @param xitem - Something that should be described as an interface (check promise list)
   */
  private getSex(xitem: IApplicationSummary): string {
    const item: Record<string, any> = xitem
    let sexKey = '';
    ['applicantId', 'coApplicantId'].forEach(key => {
      let male = -1
      if (item[key]) {
        // This is for anonymized data only!
        if (item[key][11] === 'X') {
          male = (Math.floor(Math.random() * 9) + 1) % 2
        } else {
          male = item[key][11] % 2
        }
        sexKey += male === 0 ? 'M' : 'F'
      }
    })
    return sexKey
  }

  private aggregateItems = (list: IApplicationSummary[]) => {
    const months: Record<string, any> = {}
    const years: Record<string, any> = {}
    const weeks: Record<string, any> = {}
    const weekdays: Record<string, any> = {}
    const days: Record<string, any> = {}
    const all: Record<string, any> = {}
    const sexes: Record<string, any> = {}
    const ages: Record<string, any> = {}

    list.forEach((item: IApplicationSummary) => {
      const date = new Date(item.dateOfIssue)
      const year = date.getFullYear()
      const week = this.getWeek(item.dateOfIssue)
      const month = date.getMonth() + 1 // Month is 0 - 11
      const day = date.getDate()
      let weekday = date.getDay()
      // Make Sunday 7 instead of 0
      if (weekday === 0) {
        weekday = 7
      }
      const sex = this.getSex(item)
      const age = StatisticsComponent.CalculateAge(item)
      years[year] = years[year] || []
      months[year + '-' + month] = months[year + '-' + month] || []
      weeks[year + '-' + week] = weeks[year + '-' + week] || []
      weekdays[weekday] = weekdays[weekday] || []
      days[day] = days[day] || []
      all[0] = all[0] || []
      sexes[sex] = sexes[sex] || []
      ages[age] = ages[age] || []
      const object = {
        date: item.dateOfIssue,
        status: item.status,
        reason: item.statusReason,
        amount: item.seekedAmount,
        kalp: item.kalp,
        week,
        month,
        year,
        weekday,
        day,
        all,
        sex,
        age
      }
      years[year].push(object)
      months[year + '-' + month].push(object)
      weeks[year + '-' + week].push(object)
      weekdays[weekday].push(object)
      days[day].push(object)
      all[0].push(object)
      sexes[sex].push(object)
      ages[age].push(object)
    })
    return {
      weeks,
      months,
      years,
      weekdays,
      days,
      all,
      sexes,
      ages
    }
  }

  private reduceList = (list: any[]) => {
    const count = list.length
    const result: any = {
      key: undefined as any,
      total: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
      issuable: 0,
      uc: 0,
      kalp: 0,
      amounts: {
        rejected: 0,
        approved: 0,
        pending: 0
      },
      quotas: {
        pending: 0,
        approved: 0,
        rejected: 0,
        auto: 0,
        uc: 0,
        kalp: 0,
        issuable: 0
      },
      day: 0,
      week: 0,
      weekday: 0,
      month: 0,
      year: 0,
      sex: '',
      age: ''
    }
    list.forEach(item => {
      ['pending', 'approved', 'rejected'].forEach(key => {
        result[key] += item.status === key ? 1 : 0
        result.amounts[key] += item.status === key ? item.amount : 0
      })
      if (item.reason) {
        ['kalp', 'uc'].forEach(key => {
          result[key] += item.reason === key ? 1 : 0
        })
      }
      if (item.status === 'issuable') {
        result.issuable++
      }
    })
    result.total = result.approved + result.rejected + result.pending
    result.quotas.uc = result.uc / result.rejected * 100
    result.quotas.kalp = result.kalp / result.rejected * 100
    result.quotas.issuable = result.issuable / count * 100
    result.quotas.pending = result.pending / count * 100
    result.quotas.auto = (count - result.pending) / count * 100;
    ['approved', 'rejected'].forEach(key => {
      result.quotas[key] += (result[key] / (count - result.pending) * 100)
    })

    result.week = list[0].week
    result.month = list[0].month
    result.year = list[0].year
    result.day = list[0].day
    result.weekday = list[0].weekday
    result.sex = list[0].sex
    result.age = list[0].age

    return result
  }

  private summarize(list: any): IStatistics[] {
    Object.keys(list).forEach(key => {
      list[key] = this.reduceList(list[key])
    })
    // Turn it to a sorted array
    return Object.keys(list).map(key => {
      const obj = list[key]
      obj.key = key
      return obj
    }).sort((a: IStatistics, b: IStatistics) => {
      return a.key < b.key ? 1 : 0
    })
  }
}
