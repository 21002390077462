<div class="filter-holder">
  <button (click)="register()" color="primary" mat-raised-button>
    Registrera lånelöfte/limit
  </button>
  <div>
    <mat-form-field>
      <mat-label>Kontor</mat-label>
      <mat-select [formControl]="filter.controls.office" ngDefaultControl
                  placeholder="Alla">
        <mat-option [value]="null">Alla</mat-option>
        @for (office of offices; track $index) {
          <mat-option
            [value]="office">{{ office }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Handläggare</mat-label>
      <mat-select [formControl]="filter.controls.assignee" ngDefaultControl
                  placeholder="Alla">
        <mat-option [value]="null">Alla</mat-option>
        @for (assignee of loanService.users$ | async; track $index) {
          <mat-option
            [value]="assignee.name">{{ assignee.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field [style.width.px]="140">
      <mat-label>Välj kundtyp</mat-label>
      <mat-select [formControl]="filter.controls.registerType" ngDefaultControl
                  placeholder="Alla">
        <mat-option [value]="null">Alla</mat-option>
        <mat-option value="private">Privat</mat-option>
        <mat-option value="company">Företag</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field [style.width.px]="150">
      <mat-label>Välj status</mat-label>
      <mat-select [formControl]="filter.controls.status" ngDefaultControl
                  placeholder="Alla">
        <mat-option [value]="null">Alla</mat-option>
        <mat-option value="registered">EKP</mat-option>
        <mat-option value="simpleLoanPromise">LL Alla</mat-option>
        <mat-option value="approved">LL Automatiskt</mat-option>
        <mat-option value="manually_issued">LL Manuellt</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field [style.width.px]="165">
      <mat-label>Sök kund</mat-label>
      <input [formControl]="filter.controls.text"
             matInput>
    </mat-form-field>
    <button
      [routerLink]="['/admin/classic']"
      [style.margin-left.px]="12" mat-raised-button>Hämta lånelöfte
    </button>

  </div>
</div>

<table [dataSource]="dataSource" aria-label="register-list" class="mat-elevation-z8"
       mat-table matSort>

  <ng-container matColumnDef="dateOfIssue">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Beviljat datum</th>
    <td *matCellDef="let loanPromise"
        mat-cell>{{ loanPromise.dateOfIssue | date:'yyyy-MM-dd' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="validThru">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Förfallodag</th>
    <td *matCellDef="let loanPromise"
        mat-cell>{{ loanPromise.validThru | date:'yyyy-MM-dd' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="applicantFull">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Huvudlåntagare</th>
    <td (click)="isRegistration(loanPromise) && register(loanPromise['loan-promise-id'])" (keyup)="isRegistration(loanPromise) && register(loanPromise['loan-promise-id'])"
        *matCellDef="let loanPromise"
        [class.clickable]="isRegistration(loanPromise)"
        mat-cell
    >
      <span>{{ loanPromise.applicantFull }}</span><br>
      <span class="sub-text">{{ loanPromise.applicantId }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="coApplicantFull">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Medlåntagare</th>
    <td *matCellDef="let loanPromise" mat-cell>
      <span>{{ loanPromise.coApplicantFull }}</span><br>
      <span class="sub-text">{{ loanPromise.coApplicantId }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
    <td *matCellDef="let loanPromise" class="icon" mat-cell>
      <spb-status-indicator [loanPromise]="loanPromise"></spb-status-indicator>
    </td>
  </ng-container>

  <ng-container matColumnDef="approvedAmount">
    <th *matHeaderCellDef class="header-number" mat-header-cell mat-sort-header>
      Belopp
    </th>
    <td *matCellDef="let loanPromise" class="number" mat-cell>
      @if (loanPromise) {
        <span>{{ loanPromise.approvedAmount - (loanPromise.usedAmount || 0) | number:'1.0-0':'fr' }}</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="office">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Kontor</th>
    <td *matCellDef="let loanPromise" mat-cell>{{ loanPromise.office }}</td>
  </ng-container>

  <ng-container matColumnDef="assignee">
    <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">
      Tilldelad
    </th>
    <td *matCellDef="let loanPromise" mat-cell>

      <button
        (click)="delegate(loanPromise)"
        class="custom-button"
        mat-raised-button
      >

        {{ loanPromise.adminData?.assignee || (loanPromise.adminData?.office ? 'Tilldela handläggare' : 'Tilldela') }}

      </button>

    </td>
  </ng-container>


  <ng-container matColumnDef="print">
    <th *matHeaderCellDef class="center" mat-header-cell>Skriv ut</th>
    <td *matCellDef="let loanPromise" class="center" mat-cell>
      <mat-icon (click)="print(loanPromise['loan-promise-id'])"
                (keyup)="print(loanPromise['loan-promise-id'])"
                class="print icon clickable"
      >print
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th *matHeaderCellDef class="center" mat-header-cell>Ta bort</th>
    <td (click)="remove(loanPromise['loan-promise-id'])" (keyup)="remove(loanPromise['loan-promise-id'])"
        *matCellDef="let loanPromise"
        [matTooltip]="'Gallras ' + (loanPromise.timeStamp * 1000 | date: 'yyyy-MM-dd')"
        class="delete icon clickable center"
        mat-cell
    >
      <mat-icon class="delete">delete_forever</mat-icon>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
  <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row></tr>
  <tr class="no-data" mat-no-data-row>
    <td [attr.colspan]="columnsToDisplay.length" class="no-data">Inga lånelöften
      matchar filter...
    </td>
  </tr>
</table>

<mat-paginator [pageSizeOptions]="[20, 50, 100]" [pageSize]="20"
               showFirstLastButtons></mat-paginator>
