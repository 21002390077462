import {Component, Inject, OnInit} from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from '@angular/material/datepicker'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {
  APPLICATION_ID,
  IRegistration,
  TRegistrationPropertyType,
  TRegistrationType
} from '@sparbanken-syd/loan-backend'
import {
  FormatNumberDirective,
  PersonnummerValidatorDirective
} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter, first, tap} from 'rxjs'
import {ConfigService, ILogInState} from '../../../services/config.service'
import {RegisterService} from '../../../services/register.service'

/**
 * Formats a name string: trims, reduces multiple spaces, converts to title case
 * and handles hyphenated names.
 */
const formatName = (name: string): string =>
  name
    .trim()
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .toLowerCase()
    .replace(/(^|\s)\S/g, (c) => c.toUpperCase()) // Title case conversion
    .replace(/(?:^|-)\S/g, (c) => c.toUpperCase()) // Uppercase letter after hyphen

/**
 * Returns a form group for an applicant with name and sub fields.
 */
const getApplicantForm = () => {
  return new FormGroup({
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    sub: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)]
    })
  })
}

/**
 * Validates date comparison, ensuring validThru is not earlier than dateOfIssue.
 */
const dateComparisonValidator = (dateOfIssue: Date, validThru: Date) => {
  return (dateOfIssue && validThru && dateOfIssue > validThru) ? {datesNotValid: true} : null
}

@Component({
  selector: 'spb-register-loan-promise',
  templateUrl: './register-loan-promise.component.html',
  styleUrl: './register-loan-promise.component.scss',
  imports: [MatDialogTitle, ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatError, MatCheckbox, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatDialogActions, MatButton, MatDialogClose, PersonnummerValidatorDirective, FormatNumberDirective]
})
export class RegisterLoanPromiseComponent implements OnInit {
  public coApplicantCheckbox = new FormControl<boolean>(false)

  /**
   * Main form group for registering a loan promise.
   */
  public registerLoanPromiseForm = new FormGroup({
    dateOfIssue: new FormControl<Date | number | null>(new Date(), {
      nonNullable: true,
      validators: (control: AbstractControl) => dateComparisonValidator(control.value, this.registerLoanPromiseForm?.controls.validThru.value)
    }),
    validThru: new FormControl<Date | number | null>(null, {
      validators: (control: AbstractControl) => dateComparisonValidator(this.registerLoanPromiseForm?.controls.dateOfIssue.value, control.value)
    }),
    applicants: new FormArray([getApplicantForm()]),
    property: new FormGroup({
      type: new FormControl<TRegistrationPropertyType | null>(null, {nonNullable: true}),
      maxFee: new FormControl<number | null>(null, {nonNullable: true})
    }),
    approvedAmount: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.1)]
    }),
    type: new FormControl<TRegistrationType | null>(null, {
      nonNullable: true, validators: [Validators.required]
    }),
    remainingAmount: new FormControl<number | null>({
      value: null,
      disabled: true
    }, {nonNullable: true}),
    usedAmount: new FormControl<number>(0, {nonNullable: true}),
    otherInfo: new FormControl<string>('', {nonNullable: true}),
    purpose: new FormControl<string>('', {nonNullable: true}),
    limitFee: new FormControl<number | null>(null, {nonNullable: true}),
    unconfirmed: new FormControl<boolean>(false),
    version: new FormControl<number>(0),
    [APPLICATION_ID]: new FormControl<string>('')
  })

  constructor(
    private configService: ConfigService,
    private dialogRef: MatDialogRef<RegisterLoanPromiseComponent>,
    private registerService: RegisterService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
  }

  get applicants(): any {
    return this.registerLoanPromiseForm.controls.applicants.controls
  }

  get isPrivate(): boolean {
    return this.registerLoanPromiseForm.controls.type.value === 'private'
  }

  public ngOnInit() {
    /**
     * If data exists, load the registration and patch values into the form.
     */
    if (this.data) {
      this.registerService.getRegistered(this.data)
        .pipe(filter(Boolean))
        .subscribe({
          next: (loanPromise: IRegistration) => {
            if (loanPromise.applicants.length > 1) {
              this.coApplicantCheckbox.setValue(true)
            }
            this.registerLoanPromiseForm.patchValue(loanPromise)
            this.registerLoanPromiseForm.controls.dateOfIssue.setValue(new Date(loanPromise.dateOfIssue))
            this.registerLoanPromiseForm.controls.validThru.setValue(new Date(loanPromise.validThru))
          }
        })
    } else {
      this.addDate(6)
    }

    /**
     * Update remaining amount when approvedAmount changes.
     */
    this.registerLoanPromiseForm.controls.approvedAmount.valueChanges
      .pipe(filter(Boolean))
      .subscribe((approvedAmount: number) => {
        const utilizedAmount = this.registerLoanPromiseForm.controls.usedAmount.value
        this.registerLoanPromiseForm.controls.remainingAmount.setValue(approvedAmount - (utilizedAmount || 0))
      })

    /**
     * Update remaining amount when usedAmount changes.
     */
    this.registerLoanPromiseForm.controls.usedAmount.valueChanges
      .pipe(filter(Boolean))
      .subscribe((utilizedAmount: number) => {
        const approvedAmount = this.registerLoanPromiseForm.controls.approvedAmount.value
        this.registerLoanPromiseForm.controls.remainingAmount.setValue((approvedAmount || 0) - utilizedAmount)
      })

    /**
     * Adjust form validators based on the selected type (company/private).
     */
    this.registerLoanPromiseForm.controls.type.valueChanges
      .subscribe((type: 'company' | 'private') => {
        if (type === 'company') {
          this.registerLoanPromiseForm.controls.purpose.addValidators([Validators.required])

          this.addDate(12)
        } else {
          this.addDate(6)
          this.registerLoanPromiseForm.controls.purpose.setValidators(null)
          this.registerLoanPromiseForm.controls.purpose.reset()

        }
        this.registerLoanPromiseForm.controls.purpose.updateValueAndValidity({emitEvent: false})
      })

    /**
     * Add or remove validators based on property type changes.
     */
    this.registerLoanPromiseForm.controls.property.controls.type.valueChanges
      .subscribe((propertyType: TRegistrationPropertyType) => {
        if (propertyType === 'bostadsratt') {
          this.registerLoanPromiseForm.controls.property.controls.maxFee.addValidators([Validators.required, Validators.min(0.1)])
        } else {
          this.registerLoanPromiseForm.controls.property.controls.maxFee.setValidators(null)
          this.registerLoanPromiseForm.controls.property.controls.maxFee.reset()
        }
        this.registerLoanPromiseForm.controls.property.controls.maxFee.updateValueAndValidity({emitEvent: false})
      })

    /**
     * Add or remove co-applicant based on the checkbox status.
     */
    this.coApplicantCheckbox.valueChanges
      .subscribe((checked: boolean | null) => {
        if (checked) {
          this.registerLoanPromiseForm.controls.applicants.push(getApplicantForm())
        } else {
          this.registerLoanPromiseForm.controls.applicants.removeAt(1)
        }
      })

    /**
     * Automatically update validThru when dateOfIssue changes.
     */
    this.registerLoanPromiseForm.controls.dateOfIssue.valueChanges
      .subscribe(() => {
        this.addDate(this.isPrivate ? 6 : 12)
      })
  }

  /**
   * Saves the registration, converts dates to timestamps, and assigns admin data.
   */
  public save() {
    const registration = this.registerLoanPromiseForm.value as IRegistration
    registration.dateOfIssue = new Date(registration.dateOfIssue).getTime()
    registration.validThru = new Date(registration.validThru).getTime()

    this.configService.logInState$.pipe(filter(Boolean), first()).subscribe({
      next: (user: ILogInState) => {
        registration.adminData = {
          assignee: user.name,
          office: user.office,
          phone: user.phone
        }
        this.saveOrUpdateRegistration(registration).subscribe()
      }
    })
  }

  /**
   * Formats a name using the formatName utility if the form type is 'private'.
   */
  public formatName = (control: FormControl) => {
    if (this.isPrivate) {
      control.setValue(formatName(control.value))
    }
  }

  /**
   * Handles saving or updating the registration data.
   */
  public saveOrUpdateRegistration = (registrationData: IRegistration) => {
    const saveOrUpdateObservable = registrationData[APPLICATION_ID] ?
      this.registerService.updateRegistered(registrationData) :
      this.registerService.saveRegistered(registrationData)

    return saveOrUpdateObservable.pipe(
      tap(() => {
        // Close the dialog after save/update
        this.dialogRef.close()
      })
    )
  }

  /**
   * Adds a valid 'validThru' date by adding a specified number of months to 'dateOfIssue'.
   */
  private addDate(months: number) {
    const dateOfIssue = new Date(this.registerLoanPromiseForm.controls.dateOfIssue.value)
    const futureDate = new Date(dateOfIssue)
    futureDate.setMonth(dateOfIssue.getMonth() + months)
    this.registerLoanPromiseForm.controls.validThru.setValue(futureDate)
    this.registerLoanPromiseForm.controls.dateOfIssue.updateValueAndValidity({emitEvent: false})
  }
}
