@if (notesLogs.length > 0 || changesLog.length > 0) {
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title [style.font-size.px]="14">
        Anteckningar och ändrade uppgifter
      </mat-panel-title>
      <mat-panel-description [style.font-size.px]="14">
        @if (notesLogs.length > 0) {
          Det har gjorts anteckningar i ärendet
          <br>
        }
        @if (changesLog.length > 0) {
          Det har gjorts ändringar i ansökningsuppgifter
        }
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="notes-and-logs-holder">
      <h3>Anteckningar</h3>
      @for (log of notesLogs; track $index) {
        <spb-note-item [logItem]="log"></spb-note-item>
      }

      @if (changesLog.length > 0) {
        <h3>Ändringar</h3>
        @for (log of changesLog; track $index) {
          <spb-note-item [logItem]="log"></spb-note-item>
        }
      }
    </div>
  </mat-expansion-panel>
}

<ng-template #title let-isFirst="isFirst" let-title="title">
  <h3 [class]="'log-title' + (isFirst ? ' first' : '')">{{ title }}</h3>
</ng-template>