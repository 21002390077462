@if (loanPromise.manual) {
  <mat-icon class="icon-ko"
            matTooltip="Skickad till handläggare">
    assignment_late
  </mat-icon>
}

@switch (loanPromise.status) {
  <!-- Pending status is not sent by BE anymore so it might never be shown -->
  @case ('pending') {
    <mat-icon class="icon-ko" matTooltip="Kräver handläggning">assignment_late
    </mat-icon>
  }
  @case ('issuable') {
    <mat-icon class="icon-info" matTooltip="Godkänd">fact_check</mat-icon>
  }
  @case ('approved') {
    <mat-icon class="icon-ok" matTooltip="Automatiskt beviljad">price_check
    </mat-icon>
  }
  @case ('manually_issued') {
    <mat-icon class="icon-ok" matTooltip="Manuellt beviljad"
              svgIcon="manually_issued"></mat-icon>
  }
  @case ('registered') {
    <mat-icon matTooltip="Manuellt beviljad i EKP">
      app_registration
    </mat-icon>
  }
  @case ('declined') {
    <mat-icon class="icon-ko" matTooltip="Nekad, avslag">close</mat-icon>
  }
  @case ('rejected') {
    @if (loanPromise.statusReason?.includes('kalp')) {
      <mat-icon class="icon-ko" matTooltip="Nekad, KALP">close</mat-icon>
    } @else if (loanPromise.statusReason?.includes('uc')) {
      <mat-icon class="icon-ko" matTooltip="Nekad, UC">gavel</mat-icon>
    } @else if (loanPromise.statusReason?.includes('input')) {
      <mat-icon class="icon-ko" matTooltip="Nekad, kontroll behövs">euro
      </mat-icon>
    } @else if (loanPromise.statusReason?.includes('ucThreshold') ||
    loanPromise.statusReason?.includes('blancoLoansThreshold') ||
    loanPromise.statusReason?.includes('blancoLoansMatchingUC') ||
    loanPromise.statusReason?.includes('propertyLoansMatchingUC')) {
      <mat-icon class="icon-ko" matTooltip="Nekad, kontroll behövs">euro
      </mat-icon>
    }
  }

}
