<!-- Field title with current value -->
<div class="dialog-holder">

  <mat-stepper [linear]="true" labelPosition="bottom">
    <mat-step [stepControl]="editionForm">
      <ng-template matStepLabel>Ändrade uppgifter</ng-template>
      <ng-template matStepContent>
        <h3>Inkomster</h3>
        <div class="horizontal">
          <spb-edit-field
            [application]="application"
            [control]="editionForm.controls.income.at(0)"
            label="Inkomst (kr / mån)"
            path='applicants[0].income'
            text="Sökandes inkomst"
          ></spb-edit-field>
          @if (editionForm.controls.income.at(1)) {
            <spb-edit-field
              [application]="application"
              text="Medsökandes inkomst"
              label="Inkomst (kr / mån)"
              path="applicants[1].income"
              [control]="editionForm.controls.income.at(1)"
            ></spb-edit-field>
          }
        </div>

        <h3>Nya bostaden</h3>
        <div class="horizontal">
          <spb-edit-field
            [application]="application"
            [control]="editionForm.controls.price"
            label="Pris"
            path="property.price"
            text="Tänk pris"
          ></spb-edit-field>
          <spb-edit-field
            [application]="application"
            [control]="editionForm.controls.downPayment"
            label="Kontantinsats"
            path="property.downPayment"
            text="Kontantinsats"
          ></spb-edit-field>
        </div>

        <h3>Lån och övriga krediter</h3>
        <div class="horizontal">
          <spb-edit-field
            [application]="application"
            [control]="editionForm.controls.studyLoan"
            label="Kostnad (kr / mån)"
            path="studyLoan"
            text="Studielån"
          ></spb-edit-field>
        </div>
        @for (loan of editionForm.controls.loans.controls; track $index) {
          <!--          We dont want to show any housing (type 0) loans!-->
          @if (loan.controls!.type.value !== 0) {
            <div class="horizontal">
              <spb-edit-field
                [application]="application"
                [index]="$index + 1"
                [select]="[['Blanco',1],[ 'Kortkredit',2]]"
                text="Typ av lån"
                label="Typ"
                [path]="'loans[' + $index + '].type'"
                [control]="editionForm.controls.loans.controls.at($index)!.controls!.type"
              ></spb-edit-field>
              <spb-edit-field
                [application]="application"
                [index]="$index + 1"
                text="Belopp i kronor"
                label="Belopp"
                [path]="'loans[' + $index + '].amount'"
                [control]="editionForm.controls.loans.controls.at($index)!.controls!.amount"
              ></spb-edit-field>
            </div>
            <button class="remove-loan-button" (click)="removeLoan($index)"
                    mat-raised-button>
              Ta bort lån
            </button>
          }
        }

        <div class="buttons">
          <button (click)="addLoan()" mat-raised-button color="primary">
            Lägg till lån
          </button>
        </div>

        <h3>Befintlig bostad</h3>
        <spb-edit-field
          [application]="application"
          [control]="editionForm.controls.oldProperty.controls.futurePropertyOwnership"
          [select]="propertyStatus"
          label="Befintlig bostad"
          path="oldProperty.futurePropertyOwnership"
        ></spb-edit-field>

        <div class="horizontal">

          @if (showRentalKeep) {
            <spb-edit-field
              [application]="application"
              text="Hyra"
              label="Hyra kr/mån"
              path="oldProperty.fee"
              [control]="editionForm.controls.oldProperty.controls.fee"
            ></spb-edit-field>
          }

          @if (showLoans) {
            <spb-edit-field
              [application]="application"
              text="Lånebelopp"
              label="Lånebelopp (kronor)"
              path="oldProperty.loanAmount"
              [control]="editionForm.controls.oldProperty.controls.loanAmount"
            ></spb-edit-field>
          }
          @if (showPrice) {
            <spb-edit-field
              [application]="application"
              [text]="sellLabel"
              [label]="sellLabel + ' (kronor)'"
              path="oldProperty.price"
              [control]="editionForm.controls.oldProperty.controls.price"
            ></spb-edit-field>
          }
        </div>

        @if (showPropertyType) {
          <div class="horizontal">
            <spb-edit-field
              [application]="application"
              text="Bostadstyp"
              label="Bostadstyp"
              path="oldProperty.type"
              [select]="propertyType"
              [control]="editionForm.controls.oldProperty.controls.type"
            ></spb-edit-field>

            @if (editionForm.controls.oldProperty.controls.type.value === 2) {
              <spb-edit-field
                [application]="application"
                text="Avgift"
                label="Avgift kr/mån"
                path="oldProperty.fee"
                [control]="editionForm.controls.oldProperty.controls.fee"
              ></spb-edit-field>
            }
          </div>
        }

        <div class="buttons">
          <button (click)="dialogRef.close()"
                  mat-raised-button>Avbryt
          </button>
          <button [disabled]="editionForm.invalid || !hasChanges"
                  color="primary"
                  mat-raised-button
                  matStepperNext>Nästa
          </button>
        </div>

      </ng-template>
    </mat-step>

    <mat-step [stepControl]="notes">
      <ng-template matStepLabel>Kommentar till ändring</ng-template>
      <ng-template matStepContent>
        <div class="notes">
          <h3>Kommentar</h3>
          <mat-form-field [style.width.px]="500">
            <textarea
              [formControl]="notes"
              cdkAutosizeMaxRows="5"
              cdkAutosizeMinRows="5"
              cdkTextareaAutosize
              matInput
            ></textarea>
          </mat-form-field>
        </div>
        <div class="buttons">
          <button mat-raised-button matStepperPrevious>Tillbaka</button>
          <button (click)="onSaveClick()" [disabled]="notes.invalid"
                  color="primary"
                  mat-raised-button>Spara
          </button>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>