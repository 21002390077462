export const APPLICATION_ID = 'loan-promise-id';
/********************************************************
 * Applicants
 ********************************************************/
export var OccupationType;
(function (OccupationType) {
  OccupationType[OccupationType["PERMANENT"] = 0] = "PERMANENT";
  OccupationType[OccupationType["RETIRED"] = 1] = "RETIRED";
  OccupationType[OccupationType["TIME_LIMITED"] = 2] = "TIME_LIMITED";
  OccupationType[OccupationType["HOURLY"] = 3] = "HOURLY";
  OccupationType[OccupationType["OWN_COMPANY"] = 4] = "OWN_COMPANY";
  OccupationType[OccupationType["STUDENT"] = 5] = "STUDENT";
  OccupationType[OccupationType["UNEMPLOYED"] = 6] = "UNEMPLOYED";
})(OccupationType || (OccupationType = {}));
/********************************************************
 * Loans
 ********************************************************/
export var LoanType;
(function (LoanType) {
  LoanType[LoanType["NONE"] = -1] = "NONE";
  LoanType[LoanType["HOUSING"] = 0] = "HOUSING";
  LoanType[LoanType["BLANCO"] = 1] = "BLANCO";
  LoanType[LoanType["CARD_CREDIT"] = 2] = "CARD_CREDIT";
})(LoanType || (LoanType = {}));
/********************************************************
 * Properties
 ********************************************************/
export var PropertyType;
(function (PropertyType) {
  PropertyType[PropertyType["NONE"] = -1] = "NONE";
  PropertyType[PropertyType["VILLA"] = 0] = "VILLA";
  PropertyType[PropertyType["FRITIDSHUS"] = 1] = "FRITIDSHUS";
  PropertyType[PropertyType["BOSTADSRATT"] = 2] = "BOSTADSRATT";
  PropertyType[PropertyType["HYRES"] = 3] = "HYRES";
})(PropertyType || (PropertyType = {}));
/**
 * - Use
 */
export const TFuturePropertyOwnership = ['noSell', 'confirmedContract', 'intendToSell', 'intendToSellEditable', 'intendToSellEditableByPrice', 'keepingProperty', 'rentalLeave', 'rentalKeep'];
export const RegistrationTypes = ['company', 'private'];
export const propertyTypeMapping = {
  'bostadsratt': 'Bostadsrätt'
};
/********************************************************
 * Kalp
 * NOTE: Please read types-intend-to-sell-readme.txt to understand more!
 ********************************************************/
// Properties that are excluded from the KALP process
export const propertiesExcludedFromKalp = ['confirmedContract'];
// Properties that receive double KALP consideration
export const propertiesWithDoubleKalp = ['keepingProperty', 'intendToSell'];
// Do a double KALP. If the KALP without old property is positive, the loan promise could be approved
// The calculation for the new property loan is based on its expected selling price.
export const propertiesWithDoubleKalpAndApprovedWithoutOldProperty = ['intendToSellEditableByPrice'];
// Properties that should not have any loans in the KALP
export const propertiesThatShouldNotHaveLoan = ['confirmedContract', 'rentalKeep', 'rentalLeave'];
