import {Component, Input} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {TApplicationLogType} from '@sparbanken-syd/loan-backend'

@Component({
  selector: 'spb-kalp-type-indicator',
  templateUrl: './kalp-type-indicator.component.html',
  styleUrl: './kalp-type-indicator.component.scss',
  imports: [MatIcon]
})
export class KalpTypeIndicatorComponent {
  @Input({required: true}) type!: TApplicationLogType
  @Input() isOverride = false
}
