import {AsyncPipe} from '@angular/common'
import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe, LogoComponent]
})
export class HeaderComponent {
  protected readonly canUseTestingTools = !environment.production

  constructor(
    public configService: ConfigService
  ) {
  }

  /**
   * Turns UC mocking on or off.
   */
  public toggleUC(event: Event): void {
    event.stopPropagation()
    this.configService.isMockUcActive$.update(value => !value)
  }
}
