<div matDialogTitle>{{ data.type }}</div>
<div class="content">
  Valfri kommentar
  <mat-form-field [style.width.px]="500">
      <textarea
        [formControl]="notes"
        cdkAutosizeMaxRows="5"
        cdkAutosizeMinRows="5"
        cdkTextareaAutosize
        matInput
      ></textarea>
  </mat-form-field>
</div>
<span [style.padding]="'0 20px'">
  Vill du {{ data.type.toLowerCase() }} lånelöftet?
</span>
<mat-dialog-actions>
  <div class="dialog-footer-buttons">
    <button [matDialogClose]="null" mat-raised-button>Nej</button>
    <button [matDialogClose]="notes.value || data.autoComment"
            color="primary"
            mat-raised-button>
      Ja
    </button>
  </div>
</mat-dialog-actions>
