<div class="title">
  <div matDialogTitle>Anteckning</div>
  <spb-kalp-type-indicator [type]="data.logType"></spb-kalp-type-indicator>

  <span>
    @if (data.logType === 'occupation') {
      Anställningsform
    } @else if (data.logType === 'oldProperty') {
      Existerande bostad
    } @else if (data.logType === 'income') {
      Inkomstkontroll
    } @else if (data.logType === 'ucThreshold') {
      UC lägre än {{ ucLowestThreshold | number }} kr
    } @else if (data.logType === 'propertyLoansMatchingUC') {
      Bostadslån är avvikande mot UC
    } @else if (data.logType === 'blancoLoansThreshold') {
      Blancolån överskriver {{ blancoHighestThreshold | number }} kr
    } @else if (data.logType === 'blancoLoansMatchingUC') {
      Blancolån är avvikande mot UC
    }
  </span>
</div>
<div class="content">
  @if (resolvable) {
    <mat-checkbox ngDefaultControl [formControl]="resolved" color="primary">
      Markera som löst?
    </mat-checkbox>
  }
  <mat-form-field [style.width.px]="500">
      <textarea
        [formControl]="notes"
        cdkAutosizeMaxRows="5"
        cdkAutosizeMinRows="5"
        cdkTextareaAutosize
        matInput
      ></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions>
  <div class="buttons">
    <button mat-raised-button matDialogClose="null">Avbryt</button>
    <button (click)="save()" [disabled]="notes.invalid" color="primary"
            mat-raised-button>Spara
    </button>
  </div>
</mat-dialog-actions>
